import {
    BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_REQUEST,
    BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST,
    BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST,
    BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_DELETE_REQUEST,
    BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_VACANCY_HIDE_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST,
    BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_JOB_BOARDS_INFO_REQUEST,
    BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST,
    BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST,
    BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST,
    BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST,
    BFF_RECRUITMENT_VACANCY_UPDATE_STORE,
} from "./actions";

export const getPageRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_PAGE_REQUEST,
        payload,
    };
};

export const getByIdRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_BY_ID_REQUEST,
        payload,
    };
};

export const addRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_ADD_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UPDATE_REQUEST,
        payload,
    };
};

export const publishRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_PUBLISH_REQUEST,
        payload,
    };
};

export const archiveRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_ARCHIVE_REQUEST,
        payload,
    };
};

export const unarchiveRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UNARCHIVE_REQUEST,
        payload,
    };
};

export const closeRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CLOSE_REQUEST,
        payload,
    };
};

export const deleteRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_DELETE_REQUEST,
        payload,
    };
};

export const cancelRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANCEL_REQUEST,
        payload,
    };
};

export const batchDeleteRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_BATCH_DELETE_REQUEST,
        payload,
    };
};

export const batchPublishRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_REQUEST,
        payload,
    };
};

export const batchPublishValidateRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_BATCH_PUBLISH_VALIDATE_REQUEST,
        payload,
    };
};

export const getTotalCandidatesRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_TOTAL_CANDIDATES_REQUEST,
        payload,
    };
};

export const importFromBoardsRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_IMPORT_FROM_BOARDS_REQUEST,
        payload,
    };
};

export const getRecruitmentVacancyCounters = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATE_RESPONSE_COUNTERS_REQUEST,
        payload,
    };
};

export const getPageRecruitmentVacancyCandidates = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CANDIDATES_GET_PAGE_REQUEST,
        payload,
    };
};

export const hideRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_HIDE_REQUEST,
        payload,
    };
};

export const getJobBoardsInfoRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_JOB_BOARDS_INFO_REQUEST,
        payload,
    };
};

export const updateFromJobBoardRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UPDATE_FROM_JOB_BOARD_REQUEST,
        payload,
    };
};

export const createAtJobBoardRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_CREATE_AT_JOB_BOARD_REQUEST,
        payload,
    };
};

export const updateAtJobBoardRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UPDATE_AT_JOB_BOARD_REQUEST,
        payload,
    };
};

export const importResponsesFromJobBoardRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_IMPORT_RESPONSES_FROM_JOB_BOARD_REQUEST,
        payload,
    };
};

export const exportRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_EXPORT_REQUEST,
        payload,
    };
};

export const addCandidateRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_ADD_CANDIDATE_REQUEST,
        payload,
    };
};

export const getLogPageRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_LOG_PAGE_REQUEST,
        payload,
    };
};

export const getFeedListRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_FEED_LIST_REQUEST,
        payload,
    };
};

export const addResponseByResumeUrlRecruitmentVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_ADD_RESPONSE_BY_RESUME_URL_REQUEST,
        payload,
    };
};

export const getVacancyJobBoards = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_JOB_BOARDS_REQUEST,
        payload,
    };
};

export const updateVacancyJobBoardAccount = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UPDATE_JOB_BOARD_ACCOUNT_REQUEST,
        payload,
    };
};

export const updateRecruitmentVacancyStore = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_UPDATE_STORE,
        payload,
    };
};

export const getRecruitersWithAccessVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_RECRUITERS_WITH_ACCESS_REQUEST,
        payload,
    };
};

export const getAvailableRecruitersForVacancy = (payload) => {
    return {
        type: BFF_RECRUITMENT_VACANCY_GET_AVAILABLE_RECRUITERS_REQUEST,
        payload,
    };
};