import {
    BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
} from "./actions";

export const getTotalCountsRecruitmentAccessControl = (payload) => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
        payload,
    };
};

export const clearRecruitmentAccessControlStore = () => {
    return {
        type: BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE,
    };
};