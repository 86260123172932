import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import Text from "../../../../../../components/ActualComponents/Text";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import ExtLink from "../../../../../../components/ExtLink";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import {
    clearRecruitmentJobBoardsStore,
    getRecruitmentJobBoardSubscriptions,
} from "../../../../../../ducks/bff/recruitment/jobBoardsWebhook/actionCreators";
import {
    bffRecruitmentJobBoardsProgressSelector,
    bffRecruitmentJobBoardsWebhookListSelector,
    bffRecruitmentJobBoardsWebhookTotalPagesSelector,
} from "../../../../../../ducks/bff/recruitment/jobBoardsWebhook/selectors";

import {useDebounceFetch} from "../../../../../../hooks/useDebounceFetch";
import {useInfiniteScrollPagination} from "../../../../../../hooks/useInfiniteScrollPagination";

import {isLastElementInArray} from "../../../../../../utils/objectHelper";
import {handleFormString} from "../../../../../../utils/stringHelper";

import {PAGE_DEFAULT_SIZE} from "../../../../../../constants/page";

import {clientCurrentMemberSelector} from "../../../../../../ducks/clientMember";

export const RecruitmentJobBoardsSubscriptions = (props) => {
    const {
        jobBoardAccountId,
        onClose,
    } = props;
    const dispatch = useDispatch();

    const currentMember = useSelector(clientCurrentMemberSelector);
    const list = useSelector((state) => bffRecruitmentJobBoardsWebhookListSelector(
        state,
        currentMember.seniorRecruiter,
    ));
    const progress = useSelector(bffRecruitmentJobBoardsProgressSelector);
    const totalPages = useSelector(bffRecruitmentJobBoardsWebhookTotalPagesSelector);

    const fetchList = ({pageNum, isLoadMore}) => {
        dispatch(getRecruitmentJobBoardSubscriptions({
            pageNum,
            isLoadMore,
            pageSize: PAGE_DEFAULT_SIZE,
            jobBoardAccountId,
            title: handleFormString(title),
        }));
    };

    useEffect(() => {
        return () => {
            dispatch(clearRecruitmentJobBoardsStore());
        };
    }, []);

    const {
        pageData,
        infiniteScrollProps,
        setPageData,
    } = useInfiniteScrollPagination({
        totalPages,
        fetchCallback: ({pageNum}) => {
            fetchList({
                pageNum,
                isLoadMore: true,
            });
        },
    });

    const {
        setValueFilter: setTitle,
        valueFilter: title,
    } = useDebounceFetch({
        fetchCallback: () => {
            if (pageData.pageNum !== 1) {
                setPageData({
                    pageNum: 1,
                });
            }

            fetchList({
                pageNum: 1,
            });
        },
    });

    const onClear = () => {
        setPageData({
            pageNum: 1,
        });
        setTitle("");
    };

    const getContent = () => {
        return list.map((item, index) => {
            const className = isLastElementInArray(list, index) ? "" : "mb-3 mb-md-4";

            return (
                <Text
                    key={item.vacancyId}
                    level="3"
                    noWrap={true}
                    className={className}
                >
                    {
                        item.link ?
                            <ExtLink
                                title="Перейти в вакансию"
                                to={item.link}
                            >
                                {item.text}
                            </ExtLink> :
                            item.text
                    }
                </Text>
            );
        });
    };

    return (
        <NmModal
            size="md"
            isAutoSize={true}
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Вакансии
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    submitBtnContent="Закрыть"
                    submit={onClose}
                />
            }
            searchContent={
                <div className="d-flex flex-column flex-md-row align-items-md-end">
                    <NmInputV2
                        size="xl"
                        label="Вакансии для просмотра"
                        placeholder="Введите номер или название вакансии"
                        value={title}
                        maxLength={255}
                        onChange={(event, {value}) => {
                            setTitle(value);
                        }}
                    />
                    <NmButton
                        size="xl"
                        className="ms-0 mt-2 mt-md-0 ms-md-4"
                        color="grey"
                        onClick={onClear}
                    >
                        Очистить
                    </NmButton>
                </div>
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                {getContent()}
                {
                    infiniteScrollProps &&
                    <div {...infiniteScrollProps} />
                }
            </NmForm>
        </NmModal>
    );
};