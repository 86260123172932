import {
    BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST,
    BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS,
    BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE,
} from "./actions";

const initial = {
    statusPageData: {},
    statusList: [],
    statusTotalCount: 0,
    jobRequirementPageData: {},
    jobRequirementList: [],
    jobRequirementTotalCount: 0,
    funnelPageData: {},
    funnelList: [],
    funnelTotalCount: 0,
    observersList: [],
    observersPageData: {},
    observersTotalCount: 0,
    observersVacancies: [],
    observersVacanciesTotalCount: 0,
    observersVacanciesPageData: {},
    observersProgress: false,
    statusListProgress: false,
    progress: false,
    progressAction: false,
    counts: {},
    error: null,
    avitoProfessionsDict: {},
    avitoSchedulesDict: {},
    avitoExperiencesDict: {},
    avitoBillingTypesDict: {},
    avitoBusinessAreasDict: {},
    avitoActualBusinessAreasDict: {},
    rejectionReasonsList: [],
    rejectionReasonsPageData: {},
    rejectionReasonsTotalCount: 0,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST:
        return {
            ...state,
            progress: true,
            rejectionReasonsPageData: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS:
        return {
            ...state,
            progress: false,
            rejectionReasonsList: payload.results,
            rejectionReasonsTotalCount: payload.totalCount,
        };
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR:
        return {
            ...state,
            progress: false,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS:
        return {
            ...state,
            avitoBusinessAreasDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS:
        return {
            ...state,
            avitoActualBusinessAreasDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS:
        return {
            ...state,
            avitoSchedulesDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS:
        return {
            ...state,
            avitoExperiencesDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS:
        return {
            ...state,
            avitoBillingTypesDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS:
        return {
            ...state,
            avitoProfessionsDict: payload,
        };
    case BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST: {
        return {
            ...state,
            statusPageData: payload,
            statusListProgress: true,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST: {
        return {
            ...state,
            jobRequirementPageData: payload,
            progress: true,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST: {
        return {
            ...state,
            funnelPageData: payload,
            progress: true,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS: {
        return {
            ...state,
            statusList: payload.results,
            statusTotalCount: payload.totalCount,
            statusListProgress: false,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS: {
        return {
            ...state,
            jobRequirementList: payload.results,
            jobRequirementTotalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS: {
        return {
            ...state,
            funnelList: payload.results,
            funnelTotalCount: payload.totalCount,
            progress: false,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR: {
        return {
            ...state,
            progress: false,
            statusListProgress: false,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST: {
        return {
            ...state,
            progressAction: true,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS:
    case BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR: {
        return {
            ...state,
            progressAction: false,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS: {
        return {
            ...state,
            counts: payload,
        };
    }
    case BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};