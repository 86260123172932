import React from "react";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

import Filter from "../../../../components/ActualComponents/Filter";
import NmEmptyPageV2 from "../../../../components/ActualComponents/NmEmptyPageV2";
import NmListCard from "../../../../components/ActualComponents/NmList/Card";
import {BaseDirectoryControls} from "../../../../components/BasicDirectory/components/Controls";
import CheckboxList from "../../../../components/CheckboxList";
import NmPage from "../../../../components/NmPage";
import {NmPageHeader} from "../../../../components/NmPageHeader";
import NmSelectedList from "../../../../components/NmSelectedList";
import {
    bffRecruitmentAccessControlObserverListSelector,
    bffRecruitmentAccessControlObserversProgressSelector,
    bffRecruitmentAccessControlObserverTotalCountSelector,
    bffRecruitmentAccessControlObserverTotalPagesSelector,
} from "../../../../ducks/bff/recruitment/access-control/observers/selectors";
import {RecruitmentAccessControlObserverEdit} from "./components/edit";

import {useFilter} from "../../../../hooks/useFilter";
import {useModal} from "../../../../hooks/useModal";
import {usePagination} from "../../../../hooks/usePagination";
import useRecruitmentDirectoriesObserverFilters from "./hooks/useAction";
import {
    useRecruitmentDirectoriesObserverFetch,
} from "./hooks/useFetch";

import {
    ls,
    USER_ROLE,
} from "../../../../utils/localstorage";
import {phoneFormat} from "../../../../utils/stringFormat";

import {
    ADMIN,
    CLIENT_ADMIN,
    RECRUITER,
} from "../../../../constants/roles";

import {clientCurrentMemberSelector} from "../../../../ducks/clientMember";

export const ClientRecruitmentAccessControlObservers = (props) => {
    const {
        match: {
            params: {
                clientId,
            },
        },
    } = props;

    const {seniorRecruiter} = useSelector(clientCurrentMemberSelector);
    const list = useSelector((state) => bffRecruitmentAccessControlObserverListSelector(
        state,
        seniorRecruiter,
    ));
    const totalCount = useSelector(bffRecruitmentAccessControlObserverTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentAccessControlObserverTotalPagesSelector);
    const progressList = useSelector(bffRecruitmentAccessControlObserversProgressSelector);

    const role = ls(USER_ROLE);

    const {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
    } = usePagination("nm-page");

    const {
        onClear,
        onSearch,
        filterData,
    } = useFilter({
        pageSize,
        setPagination,
    });

    const {
        fetchList,
    } = useRecruitmentDirectoriesObserverFetch({
        clientId,
        pageNum,
        pageSize,
        filterData,
    });

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        modalData: editData,
        onCloseModal: onCloseEdit,
    } = useModal();

    const {
        filters,
    } = useRecruitmentDirectoriesObserverFilters({
        clientId,
    });

    const getVacanciesForView = (item) => {
        if (isEmpty(item.vacanciesList)) {
            return "-";
        }

        return (
            <NmSelectedList
                showedItemsCount={3}
                showListWithoutValue={true}
                list={item.vacanciesList}
                modalTitle="Вакансии"
                dropdownWidth={250}
                isShowDetailModal={true}
            />
        );
    };

    const getRows = () => {
        return list.map(item => {
            return {
                key: item.id,
                contentRow: (
                    <NmListCard
                        classNameMainContent="col-16 col-xxl-14"
                        primaryHeader={item.observerName}
                        noDivider={true}
                        labels={[
                            {
                                label: "Должность",
                                text: item.positionName,
                            },
                            {
                                label: "Номер телефона",
                                text: item.phone ? phoneFormat(item.phone) : "-",
                            },
                            {
                                label: "E-mail",
                                text: item.email || "-",
                            },
                            {
                                label: "Роль",
                                text: item.roleName || "-",
                            },
                            {
                                label: "Вакансии для просмотра",
                                text: getVacanciesForView(item),
                                columnOnMobile: true,
                            },
                        ]}
                        isFixedActions={true}
                        actionsClassName="col-2"
                        actions={
                            <BaseDirectoryControls
                                onClickEdit={() => {
                                    onOpenEdit(item);
                                }}
                                isVisibleControls={[ADMIN, CLIENT_ADMIN].includes(role) || role === RECRUITER && seniorRecruiter}
                            />
                        }
                    />
                ),
            };
        });
    };

    return (
        <NmPage
            header={
                <NmPageHeader
                    noWrap={false}
                    text="Наблюдатели"
                    tooltipText="Страница предназначена для управления наблюдателями компании"
                />
            }
            filtersBase={
                <Filter
                    initState={filterData}
                    filters={filters}
                    onSubmit={onSearch}
                    clearFilter={onClear}
                />
            }
            typeFilter="vertical"
            onPaginationChange={onPaginationChange}
            onChangePageSize={onChangePageSize}
            currentPageSize={pageSize}
            currentPageNum={pageNum}
            totalPages={totalPages}
            totalCount={totalCount}
            isLoaded={progressList}
        >
            {
                isOpenEdit &&
                <RecruitmentAccessControlObserverEdit
                    onClose={onCloseEdit}
                    id={editData.id}
                    clientUserId={editData.clientUserId}
                    clientId={clientId}
                    fetchList={fetchList}
                />
            }
            {
                list.length ?
                    <CheckboxList rows={getRows()} /> :
                    <NmEmptyPageV2 title="Данные отсутствуют" />
            }
        </NmPage>
    );
};