import {all, put, takeEvery} from "@redux-saga/core/effects";

import {
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS,
} from "./actions";

import request from "../../../../utils/postman";
import {toastError} from "../../../../utils/toastHelper";

const controller = "/adm/recruitment/access-control";

//POST /bff/adm/recruitment/access-control/totalCounts
// Получить счетчики для вкладок раздела управления доступом
const getTotalCountsRecruitmentAccessControl = function* ({payload}) {
    try {
        const result = yield request.bff.post(`${controller}/totalCounts?clientId=${payload.clientId}`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST, getTotalCountsRecruitmentAccessControl),
    ]);
}