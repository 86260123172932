import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CHAT_MESSAGES, DISPUTE_MESSAGES} from "../constants/messages";

const controller = "/chat";

const CHAT_LIST_GET_REQUEST = "CHAT_LIST_GET_REQUEST";
const CHAT_LIST_GET_SUCCESS = "CHAT_LIST_GET_SUCCESS";
const CHAT_LIST_GET_ERROR = "CHAT_LIST_GET_ERROR";

const CHAT_LOCK_USER_REQUEST = "CHAT_LOCK_USER_REQUEST";
const CHAT_LOCK_USER_SUCCESS = "CHAT_LOCK_USER_SUCCESS";
const CHAT_LOCK_USER_ERROR = "CHAT_LOCK_USER_ERROR";

const CHAT_GET_BY_ID_REQUEST = "CHAT_GET_BY_ID_REQUEST";
const CHAT_GET_BY_ID_SUCCESS = "CHAT_GET_BY_ID_SUCCESS";
const CHAT_GET_BY_ID_ERROR = "CHAT_GET_BY_ID_ERROR";

const CHAT_CREATE_REQUEST = "CHAT_CREATE_REQUEST";
const CHAT_CREATE_ERROR = "CHAT_CREATE_ERROR";

const CHAT_GET_UNREAD_COUNT_REQUEST = "CHAT_GET_UNREAD_COUNT_REQUEST";
const CHAT_GET_UNREAD_COUNT_SUCCESS = "CHAT_GET_UNREAD_COUNT_SUCCESS";
const CHAT_GET_UNREAD_COUNT_ERROR = "CHAT_GET_UNREAD_COUNT_ERROR";

const CHAT_LIST_UPDATE_STORE = "CHAT_LIST_UPDATE_STORE";


//*  INITIAL STATE  *//

const initial = {
    chatList: [],
    guid: "",
    loading: false,
    pageData: {},
    totalCount: 0,
    chat: {},
    isLockedSuccess: false,
    chatUnreadCount: 0,
};

//*  REDUCER  *//

export default (state = initial, {type, payload}) => {
    switch (type) {
    case CHAT_LIST_UPDATE_STORE:
        const {
            field,
            value,
        } = payload;
        return {
            ...state,
            [field]: value,
        };
    case CHAT_LIST_GET_REQUEST:
        return {
            ...state,
            pageData: payload,
            isLockedSuccess: false,
            loading: true,
        };
    case CHAT_GET_BY_ID_SUCCESS:
        return {
            ...state,
            chat: payload,
        };
    case CHAT_LIST_GET_SUCCESS:
        return {
            ...state,
            chatList: payload.chats,
            totalCount: payload.totalCount,
            loading: false,
        };
    case CHAT_GET_BY_ID_ERROR:
    case CHAT_LOCK_USER_SUCCESS:
        return {
            ...state,
            isLockedSuccess: true,
            loading: false,
        };
    case CHAT_GET_UNREAD_COUNT_SUCCESS:
        const {
            unreadMessagesCount,
        } = payload;

        return {
            ...state,
            chatUnreadCount: unreadMessagesCount,
        };
    case CHAT_LIST_GET_ERROR:
        return {
            ...state,
            loading: false,
        };
    default:
        return state;
    }
};

//*  ACTION CREATORS  *//
export function getSimpleChatList(payload) {
    return {type: CHAT_LIST_GET_REQUEST, payload};
}

export function getChatById(payload) {
    return {type: CHAT_GET_BY_ID_REQUEST, payload};
}

export function lockUserChat(payload) {
    return {type: CHAT_LOCK_USER_REQUEST, payload};
}

export function createChat(payload) {
    return {
        type: CHAT_CREATE_REQUEST,
        payload,
    };
}

export function updateFieldChatListStore(field, value) {
    return {
        type: CHAT_LIST_UPDATE_STORE,
        payload: {
            field,
            value,
        },
    };
}

export function getChatUnreadCount() {
    return {
        type: CHAT_GET_UNREAD_COUNT_REQUEST,
    };
}

//*  SELECTORS  *//

export const chatListSelector = state => state.chats;

export const chatListAllSelector = createSelector(chatListSelector, ({chatList}) => chatList);

export const chatIsLockedSuccessSelector = createSelector(chatListSelector, ({isLockedSuccess}) => isLockedSuccess);

export const chatSelector = createSelector(chatListSelector, ({chat}) => chat);

export const chatListTotalPagesSelector = createSelector(chatListSelector, ({totalCount, pageData}) => {
    const {pageSize} = pageData;
    return pageSize ? Math.ceil(totalCount / pageSize) : 0;
});

export const chatUnreadCountSelector = createSelector(chatListSelector, ({chatUnreadCount}) => chatUnreadCount);

//*  SAGA  *//

export const getSimpleChatListSaga = function* (action) {
    try {
        const {payload} = action;
        const result = yield request.post(`${controller}/getPageByChatId`, payload);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_LIST_GET_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHAT_LIST_GET_SUCCESS, payload: result});
    } catch (error) {
        toastError(DISPUTE_MESSAGES.DISPUTE_GET_LIST_ERROR);

        yield put({
            type: CHAT_LIST_GET_ERROR,
            payload: error,
        });
    }
};

export const getChatByIdSaga = function* ({payload}) {
    try {
        const {
            chatId,
            getResult,
        } = payload;

        const result = yield request.get(`${controller}/getById/${chatId}`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_GET_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        if (getResult) {
            getResult(result);
        }

        yield put({type: CHAT_GET_BY_ID_SUCCESS, payload: result});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHAT_GET_BY_ID_ERROR,
            payload: error,
        });
    }
};

export const lockUserChatSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
            chatId,
            locked,
        } = payload;
        const result = yield request.get(`${controller}/locked/${chatId}/${locked}`, {});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_LOCK_USER_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        if (locked) {
            toastSuccess(CHAT_MESSAGES.LOCK_CHAT_SUCCESS);
        } else {
            toastSuccess(CHAT_MESSAGES.UNLOCK_CHAT_SUCCESS);
        }

        onSuccess();
        yield put({type: CHAT_LOCK_USER_SUCCESS, payload: result});
        yield put({type: CHAT_GET_BY_ID_REQUEST, payload: {chatId}});
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHAT_LOCK_USER_ERROR,
            payload: error,
        });
    }
};

export const createChatSaga = function* (action) {
    try {
        const {payload} = action;
        const {clientId, contractorId, orderId, pushChatLink} = payload;
        const result = yield request.post(`${controller}/add`, {clientId, contractorId, orderId});
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_CREATE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }
        pushChatLink(result.guid, orderId, clientId);
    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHAT_CREATE_ERROR,
            payload: error,
        });
    }
};

export const getChatUnreadCountSaga = function* () {
    try {
        const result = yield request.get(`${controller}/unread/count`);
        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CHAT_GET_UNREAD_COUNT_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({type: CHAT_GET_UNREAD_COUNT_SUCCESS, payload: result});

    } catch (error) {
        toastError(error.message);

        yield put({
            type: CHAT_GET_UNREAD_COUNT_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CHAT_LIST_GET_REQUEST, getSimpleChatListSaga),
        takeEvery(CHAT_GET_BY_ID_REQUEST, getChatByIdSaga),
        takeEvery(CHAT_LOCK_USER_REQUEST, lockUserChatSaga),
        takeEvery(CHAT_CREATE_REQUEST, createChatSaga),
        takeEvery(CHAT_GET_UNREAD_COUNT_REQUEST, getChatUnreadCountSaga),
    ]);
}

