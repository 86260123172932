import {
    BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST,
    BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS,
} from "./actions";

const initial = {
    recruitersGroupsTotalCount: 0,
    jobBoardsTotalCount: 0,
    observersTotalCount: 0,
    totalCountsLoading: false,
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_REQUEST:
        return {
            ...state,
            totalCountsLoading: true,
        };
    case BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_SUCCESS:
        return {
            ...state,
            totalCountsLoading: false,
            jobBoardsTotalCount: payload.jobBoardsTotalCount,
            observersTotalCount: payload.observersTotalCount,
            recruitersGroupsTotalCount: payload.recruitersGroupsTotalCount,
        };
    case BFF_RECRUITMENT_ACCESS_CONTROL_GET_TOTAL_COUNTS_ERROR:
        return {
            ...state,
            totalCountsLoading: false,
        };
    case BFF_RECRUITMENT_ACCESS_CONTROL_CLEAR_STORE:
        return initial;
    default: return state;
    }
};