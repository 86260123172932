import React from "react";
import {useSelector} from "react-redux";

import NmDropdownV2 from "../../../../../components/ActualComponents/NmDropdownV2";
import NmForm from "../../../../../components/ActualComponents/NmForm";
import NmModal from "../../../../../components/ActualComponents/NmModal";
import NmTextareaV2 from "../../../../../components/ActualComponents/NmTextareaV2";
import ApplyButtons from "../../../../../components/ApplyButtons";
import NmTitle from "../../../../../components/NmTitle";
import {RecruitmentAccountDropdownWithTooltip} from "../../../../../components/Recruitment/AccountDropdownWithTooltip";
import {bffRecruitmentVacancyProgressActionSelector} from "../../../../../ducks/bff/recruitment/vacancy/selectors";

import useCreateVacancyOnJobBoardForm from "./hooks/useForm";

import {RECRUITMENT_SOURCE_JOB_BOARD, RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../constants";

const CreateVacancyOnJobBoardForm = (props) => {
    const {
        clientId,
        vacancyId,
        jobBoardType,
        data,
        isUpdate = false,
        onClose,
        fetchInfo,
    } = props;

    const progress = useSelector(bffRecruitmentVacancyProgressActionSelector);

    const {
        handleSubmit,
        values,
        touched,
        errors,
        setFieldValue,
        formConfig,
        handleChange,
    } = useCreateVacancyOnJobBoardForm({
        clientId,
        vacancyId,
        jobBoardType,
        data,
        isUpdate,
        onClose,
        fetchInfo,
    });

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Дозаполнение полей для джоб-борда
                    {" "}
                    {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[jobBoardType]}
                </NmTitle>
            }
            loading={progress}
            footer={
                <ApplyButtons
                    mobile="column"
                    cancelBtnContent="Отменить"
                    submitBtnContent={isUpdate ? "Обновить" : "Создать"}
                    onClose={onClose}
                    submit={handleSubmit}
                    disabled={progress}
                />
            }
        >
            <NmForm addMargin>
                {
                    !isUpdate &&
                    <RecruitmentAccountDropdownWithTooltip
                        required={true}
                        clientId={clientId}
                        jobBoardType={jobBoardType}
                        label="Аккаунт"
                        placeholder="Выберите"
                        name="jobBoardAccountId"
                        onChange={(event, {name, value}) => setFieldValue(name, value)}
                        value={values.jobBoardAccountId}
                        error={touched.jobBoardAccountId && errors.jobBoardAccountId}
                    />
                }
                {
                    formConfig.fields.map(item => {
                        return (
                            <NmDropdownV2
                                key={item.name}
                                required={item.required}
                                size="xl"
                                search
                                disabled={item.disabled}
                                name={item.name}
                                label={item.label}
                                placeholder="Выберите"
                                value={values[item.name]}
                                options={item.options}
                                onChange={(event, {name, value}) => setFieldValue(name, value)}
                                error={touched[item.name] && errors[item.name]}
                                tooltip={item.tooltip}
                                isVisibleTooltip={item.isVisibleTooltip}
                            />
                        );
                    })
                }
                {
                    [
                        RECRUITMENT_SOURCE_JOB_BOARD.HEAD_HUNTER,
                        RECRUITMENT_SOURCE_JOB_BOARD.ZARPLATA_RU,
                    ].includes(jobBoardType) &&
                    <NmTextareaV2
                        required
                        size="xl"
                        label="Описание вакансии"
                        placeholder="Опишите подробно детали объявления"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        maxLength={5000}
                        error={touched.description && errors.description}
                        isVisibleTooltip
                    />
                }
            </NmForm>
        </NmModal>
    );
};

export default CreateVacancyOnJobBoardForm;