import {createSelector} from "reselect";

import {getTotalPages} from "../../../../utils/mathHelper";
import {getFullName} from "../../../../utils/stringFormat";

import {RECRUITMENT_SOURCE_JOB_BOARD} from "../../../../containers/recruitment/vacancies/constants";

export const bffRecruitmentVacancySelector = state => state.bff.recruitment.vacancy;
export const bffRecruitmentVacancyListSelector = createSelector(bffRecruitmentVacancySelector, ({list}) => list);
export const bffRecruitmentVacancyTotalCountSelector = createSelector(bffRecruitmentVacancySelector, ({totalCount}) => totalCount);
export const bffRecruitmentVacancyTotalPageSelector = createSelector(bffRecruitmentVacancySelector, ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize));
export const bffRecruitmentVacancyProgressSelector = createSelector(bffRecruitmentVacancySelector, ({progress}) => progress);
export const bffRecruitmentVacancyProgressActionSelector = createSelector(bffRecruitmentVacancySelector, ({progressAction}) => progressAction);
export const bffRecruitmentVacancyProgressCheckSelector = createSelector(bffRecruitmentVacancySelector, ({progressCheck}) => progressCheck);
export const bffRecruitmentVacancyProgressCardSelector = createSelector(bffRecruitmentVacancySelector, ({progressCard}) => progressCard);
export const bffRecruitmentVacancyCardSelector = createSelector(bffRecruitmentVacancySelector, ({card}) => card);
export const bffRecruitmentVacancyCardCandidatesListSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesList}) => candidatesList);
export const bffRecruitmentVacancyCardCandidatesContractorIdsSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesList}) => {
    return candidatesList.map(item => item.candidateId).filter(Boolean);
});
export const bffRecruitmentVacancyCardCandidatesTotalCountSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesTotalCount}) => candidatesTotalCount);
export const bffRecruitmentVacancyCardCandidatesTotalPageSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesTotalCount, candidatesPageData: {pageSize = 0}}) => getTotalPages(candidatesTotalCount, pageSize));
export const bffRecruitmentVacancyCardCandidatesPageDataSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesPageData}) => candidatesPageData);
export const bffRecruitmentVacancyCardCandidatesProgressSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesProgress}) => candidatesProgress);
export const bffRecruitmentVacancyCardCandidatesProgressActionSelector = createSelector(bffRecruitmentVacancySelector, ({candidatesProgressAction}) => candidatesProgressAction);
export const bffRecruitmentVacancyCandidatesCountersSelector = createSelector(bffRecruitmentVacancySelector, ({counters}) => counters);
export const bffRecruitmentVacancyCandidatesFunnelFiltersSelector = createSelector(bffRecruitmentVacancySelector, ({counters}) => {
    const {
        totalByStatuses,
    } = counters;

    return totalByStatuses || [];
});
export const bffRecruitmentVacancyCandidatesFunnelFiltersOptionsSelector = createSelector(bffRecruitmentVacancySelector, ({counters}) => {
    const {
        totalByStatuses,
    } = counters;

    return (totalByStatuses || [])
        .filter(item => !item.isRejectStatus)
        .map(item => {
            return {
                key: item.statusId,
                value: item.statusId,
                text: item.name,
            };
        });
});
export const bffRecruitmentVacancyCardRecruitersWithAccessSelector = createSelector(bffRecruitmentVacancySelector, ({recruitersWithAccess}) => recruitersWithAccess);

export const bffRecruitmentVacancyJobBoardsListFiltersSelector = createSelector(bffRecruitmentVacancySelector, ({jobBoardsList}) => jobBoardsList);
export const bffRecruitmentVacancyJobBoardsListProgressFiltersSelector = createSelector(bffRecruitmentVacancySelector, ({jobBoardsProgress}) => jobBoardsProgress);
export const bffRecruitmentVacancyTabFilterDataSelector = createSelector(bffRecruitmentVacancySelector, ({tabFilterData}) => tabFilterData);
export const bffRecruitmentIsVacancyResponsesPageActiveSelector = createSelector(bffRecruitmentVacancySelector, ({isResponsesPageActive}) => isResponsesPageActive);
export const bffRecruitmentVacancyLogListSelector = createSelector(bffRecruitmentVacancySelector, ({logList}) => logList);
export const bffRecruitmentVacancyLogListTotalCountSelector = createSelector(bffRecruitmentVacancySelector, ({logTotalCount}) => logTotalCount);
export const bffRecruitmentVacancyLogListTotalPageSelector = createSelector(bffRecruitmentVacancySelector, ({logTotalCount, logPageData: {pageSize = 0}}) => getTotalPages(logTotalCount, pageSize));
export const bffRecruitmentVacancyLogListProgressSelector = createSelector(bffRecruitmentVacancySelector, ({logProgress}) => logProgress);
export const bffRecruitmentVacancyCandidateFeedListSelector = createSelector(bffRecruitmentVacancySelector, ({feedList}) => feedList);
export const bffRecruitmentVacancyCandidateFeedProgressSelector = createSelector(bffRecruitmentVacancySelector, ({feedProgress}) => feedProgress);
export const bffRecruitmentVacancyJobBoardsTypesSelector = createSelector(
    bffRecruitmentVacancySelector,
    ({jobBoards}) => jobBoards
        .map(item => item.jobBoardType)
        .filter(type => type !== RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX),
);
export const bffRecruitmentVacancyAvailableRecruitersOptionsSelector = createSelector(
    bffRecruitmentVacancySelector,
    ({availableRecruitersList}) => {
        return availableRecruitersList.map(item => {
            return {
                key: item.clientUserId,
                value: item.clientUserId,
                text: getFullName(
                    item.lastName,
                    item.firstName,
                    item.patronymic,
                ),
            };
        });
    },
);
