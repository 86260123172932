import React from "react";
import {useTranslation} from "react-i18next";
import Media from "react-media";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

import {ReactComponent as AttachedLogo} from "../../../images/push_pin_24.svg";
import ContextMenu from "../../ActualComponents/ContextMenu";
import HelpTooltip from "../../ActualComponents/HelpTooltip";
import NmAdvancedTooltip from "../../ActualComponents/NmAdvancedTooltip";
import Avatar from "../../Avatar";
import BankIndicator from "../../BankIndicator";
import ContractorIndividualEntrepreneurIndicator from "../../ContractorIndividualEntrepreneurIndicator";
import ContractorNoteForClientTooltip from "../../ContractorNoteForClientTooltip";
import IconBlacklist from "../../IconBlacklist";
import InstrumentPaymentIndicator from "../../InstrumentPaymentIndicator";
import LocatedOutsideRussiaTooltip from "../../LocatedOutsideRussiaTooltip";
import NmBadge from "../../NmBadge";
import NmButton from "../../NmButton";
import NmCheck from "../../NmCheck";
import NmIcon from "../../NmIcon";
import NmReadonlyRating from "../../NmReadonlyRating";
import NmSelectedList from "../../NmSelectedList";
import SelfEmployedIndicator from "../../SelfEmployedIndicator";
import {ACTION} from "../index";

import {
    useContractorPassportCheckingForm,
} from "../../../containers/contractor/contractor-general-info/components/passport-checking/hooks/useForm";

import formatDate, {convertUtcToLocal, formatLocalDate} from "../../../utils/dateFormat";
import {CURRENT_CLIENT_USER_ID, ls} from "../../../utils/localstorage";
import {pluralizeYears} from "../../../utils/pluralize";
import {
    formatAmount,
    formatNumber,
    getFullName,
    phoneFormat,
    phoneFormatWithoutSevenNumber,
} from "../../../utils/stringFormat";
import {isNullOrWhitespace} from "../../../utils/stringHelper";

import {ORDER_STATUS} from "../../../constants/clientList";
import {COLOR} from "../../../constants/color";
import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../constants/finance";
import {
    LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST,
    LINK_CONTRACTOR_PROFILE,
    LINK_ORDER_CARD,
} from "../../../constants/links";
import {
    COMPLETE_REASON,
    ORDER_CARD_PERFORMERS_TABS,
    ORDER_SOURCE_TYPES,
    REASONS_BY_STATUS,
} from "../../../constants/order";
import {
    FOREMAN,
    isClientUser,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    RECRUITER,
    RNKO,
} from "../../../constants/roles";
import {STATUS} from "../../../constants/status";

import {history} from "../../../store/configureStore";

import {getCitizenshipSelector} from "../../../ducks/contractor";

import "./style.sass";

import {SELF_CONTRACTOR, TAB_TYPE_BY_SUB_PAGE} from "../../../constants/link-params";

export const STATUS_COLOR_TRANSCRIPT = {
    HIRED: "light-green",
    CONTRACTOR_DECLINED: "red",
    CLIENT_DECLINED: "red",
    AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION: "orange",
    AWAITING_CLIENT_CONFIRMATION: "orange",
    AWAITING_CONTRACTOR_CONFIRMATION: "orange",
    AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION: "orange",
    CLIENT_PREAPPROVED: "orange",
    AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING: "orange",
    CLIENT_REVOKED: "gray",
    CONTRACTOR_REVOKED: "gray",
    BLOCKED: "gray",
    COMPLETED: "green",
};

const APPLICATION_TYPE = {
    // Откликнулся СЗ
    CONTRACTOR: "CONTRACTOR",
    // Пригласила компания
    CLIENT: "CLIENT",
};

const getLabelStatusDateTextByTab = (subpage, applicationType) => {
    const {
        PRE_APPROVED,
        SIGN_AGREEMENT,
        HIRED,
        REFUSALS,
        RESPONSES,
    } = ORDER_CARD_PERFORMERS_TABS;

    if (subpage === RESPONSES) {
        return "Дата отклика:";
    }

    if ([PRE_APPROVED, SIGN_AGREEMENT, HIRED, REFUSALS].includes(subpage)) {
        return applicationType === APPLICATION_TYPE.CONTRACTOR ? "Дата отклика:" : "Дата приглашения:";
    }

    return "Дата приглашения:";
};

const OrderContractorsCard = (props) => {
    const {
        contractor,
        isVisibleResponsesActions = true,
        isVisibleCreateAct = true,
        contractor: {
            blacklist,
            taxStatus,
            blacklistReason,
            blocked,
            blockedReason,
            contractorId,
            lastName,
            firstName,
            patronymic,
            fullName,
            overallRating,
            age,
            citizenship,
            lastPaymentDate,
            lastPaymentSum,
            orderNum,
            orderName,
            orderId,
            clientId,
            contractorStatus,
            reasonForRefusal,
            completeReason,
            reason,
            phone,
            chatId,
            orderManagerId,
            disputeOpenCount,
            checkInRequired,
            checkOutRequired,
            orderCompletedCount,
            actDate,
            currentClientOrderCompletedCount,
            workAddressInfo,
            applicationType,
            applicationDate,
            contractDate,
            refusedDate,
            smzTaxOffer,
            projectId,
            projectName,
            objectAddress,
            hasPaymentMethodCard,
            locatedOutsideRussia,
            individualEntrepreneurStatus,
            individualEntrepreneurRegistrationIssuedDateTime,
            individualEntrepreneurBankRequisitesPresent,
            noteForClient,
            pin,
        },
        currentMember: {
            paymentsCreationProhibited,
        },
        notification,
        handleCopyContact,
        showChatList,
        actionSwitcher,
        contractorStatusDict,
        isShowOrderInfo,
        role,
        subpage,
        location,
        orderStatus,
        orderSource,
        orderContractPaymentType,
        contractorsPossibility,
    } = props;

    const {t} = useTranslation();

    const citizenshipsDict = useSelector(getCitizenshipSelector);

    const _fullName = lastName ? getFullName(lastName, firstName, patronymic) : fullName;

    const contractorCitizenship = citizenship ? citizenshipsDict[citizenship] : null;
    const isIndividualEntrepreneur = orderContractPaymentType === ORDER_WORK_REPORT_TYPE.INDIVIDUAL;

    const {
        isPassportSuccess,
        passportSuccessText,
        passportErrorText,
    } = useContractorPassportCheckingForm({contractor});

    const goOrder = () => {
        const linkOrder = LINK_ORDER_CARD.replace(":clientId", clientId);

        const resultLink = linkOrder.replace(":orderId", orderId)
            .replace(":page", SELF_CONTRACTOR)
            .replace(":subpage", ORDER_CARD_PERFORMERS_TABS.RESPONSES);

        const state = {prevPath: location.pathname};

        history.push(resultLink, state);
    };

    const goProject = () => {
        const link = LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST
            .replace(":projectId", projectId)
            .replace(":clientId", clientId);

        const state = {prevPath: location.pathname};

        history.push(link, state);
    };

    const getSuggestion = () => {
        if ([STATUS.CLIENT_DECLINED, STATUS.CONTRACTOR_DECLINED].includes(contractorStatus)) {
            return reasonForRefusal;
        }

        const {[contractorStatus]: status = {}} = REASONS_BY_STATUS;

        const message = status[completeReason] || "";

        if (!reason && completeReason === COMPLETE_REASON.CONTRACTOR_REFUSE_ORDER) {
            return "Заказчик отказался от работы с исполнителем";
        }

        return !reason ? message : message.replace(":reason", reason);
    };

    const renderStatus = () => {
        if (![ORDER_CARD_PERFORMERS_TABS.HIRED, ORDER_CARD_PERFORMERS_TABS.REFUSALS].includes(subpage)) {
            return <div className="order-contractors-card__status-empty" />;
        }

        const suggestion = getSuggestion();

        return (
            <div className="order-contractors-card__status">
                <NmBadge
                    condition
                    mod={STATUS_COLOR_TRANSCRIPT[contractorStatus]}
                    text={contractorStatusDict[contractorStatus]}
                />
                {
                    suggestion &&
                    <HelpTooltip
                        width={24}
                        height={24}
                        position="top"
                        className="order-contractors-card__tooltip"
                        text={suggestion}
                    />
                }
            </div>
        );
    };

    const getHiredTabActionDropdownOptions = (contractorStatus, disputeOpenCount, checkInRequired, checkOutRequired) => {
        const option = {
            showHistory: {
                key: ACTION.SHOW_HISTORY,
                value: ACTION.SHOW_HISTORY,
                text: "История выплат",
            },
            openTimeSheet: {
                key: ACTION.TIME_SHEET,
                value: ACTION.TIME_SHEET,
                text: "Журнал учета времени",
            },
            endOrder: {
                key: ACTION.END_ORDER,
                value: ACTION.END_ORDER,
                text: "Завершить заказ",
            },
            approveEndOrder: {
                key: ACTION.APPROVE_END_ORDER,
                value: ACTION.APPROVE_END_ORDER,
                text: "Подтвердить завершение заказа",
            },
            // Временно убрали
            // declineEndOrder: {
            //     key: ACTION.DECLINE_END_ORDER,
            //     value: ACTION.DECLINE_END_ORDER,
            //     text: "Отклонить завершение заказа"
            // },
        };

        const endOrder = [STATUS.HIRED, STATUS.BLOCKED].includes(contractorStatus) &&
        ![NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, NM_CONSULTANT, RNKO, NM_PARTNER].includes(role) ?
            [option.endOrder] : [];
        const approveEndOrder = [STATUS.AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION].includes(contractorStatus) ?
            [option.approveEndOrder] : [];
        // const declineEndOrder = [STATUS.AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION].includes(contractorStatus) ?
        //     [option.declineEndOrder] : [];
        const openTimeSheet = checkInRequired || checkOutRequired ? [option.openTimeSheet] : [];

        return [
            option.showHistory,
            ...openTimeSheet,
            ...endOrder,
            ...approveEndOrder,
            //  ...declineEndOrder
        ];
    };

    const getMobileOptions = (isMobile) => {
        if (!isMobile) {
            return [];
        }

        const goChatOption = subpage !== TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.REFUSALS].LINK &&
        isShowChatButton({contractorStatus, orderManagerId}) ? [{
                key: ACTION.GO_CHAT,
                value: ACTION.GO_CHAT,
                text: "Перейти в чат",
            }] : [];

        const phoneOption = phone ? [{
            key: ACTION.COPY,
            value: ACTION.COPY,
            text: "Позвонить",
        }] : [];

        const attachToOrderOption = [
            TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.INVITED].LINK,
            TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.SIGN_AGREEMENT].LINK,
        ].includes(subpage) ? [{
                key: ACTION.ATTACH_TO_ORDER,
                value: ACTION.ATTACH_TO_ORDER,
                text: pin ? "Открепить с заказа" : "Закрепить на заказе",
            }] : [];

        return [
            ...goChatOption,
            ...phoneOption,
            ...attachToOrderOption,
        ];
    };

    const getDropdown = ({mobile, tablet}) => {
        if ([ORDER_SOURCE_TYPES.API, ORDER_SOURCE_TYPES.REGISTRY].includes(orderSource)) {
            return null;
        }
        const isMobile = mobile || tablet;

        const mobileOptions = getMobileOptions(isMobile);

        if ([TAB_TYPE_BY_SUB_PAGE[ORDER_CARD_PERFORMERS_TABS.HIRED].LINK].includes(subpage)) {
            const options = getHiredTabActionDropdownOptions(contractorStatus, disputeOpenCount, checkInRequired, checkOutRequired);

            return (
                <ContextMenu
                    className="order-contractors-card__action"
                    options={[...options, ...mobileOptions]}
                    onClickItem={({value: actionType}) => {
                        actionSwitcher({
                            actionType,
                            ...props.contractor,
                        });
                    }}
                />
            );
        }

        return isMobile ?
            <ContextMenu
                className="order-contractors-card__action"
                options={mobileOptions}
                onClickItem={({value: actionType}) => {
                    actionSwitcher({
                        actionType,
                        ...props.contractor,
                    });
                }}
            /> : null;
    };

    const getPayButton = () => {
        if (!isVisibleCreateAct) {
            return null;
        }

        const {
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
        } = STATUS;

        const isHideByStatusOrSource = orderStatus === ORDER_STATUS.CLOSED || orderSource === ORDER_SOURCE_TYPES.REGISTRY;

        const isHideByRole = (paymentsCreationProhibited && role === FOREMAN) || [
            NM_MANAGER,
            NM_COORDINATOR,
            NM_CHIEF_ACCOUNTANT,
            NM_OPERATOR,
            RECRUITER,
        ].includes(role);

        const isHideByContractorStatus = ![
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
        ].includes(contractorStatus);

        if (
            isHideByStatusOrSource ||
            isHideByRole ||
            isHideByContractorStatus ||
            orderSource === ORDER_SOURCE_TYPES.API
        ) {
            return null;
        }

        return (
            <NmButton
                color="white"
                size="lg"
                className="order-contractors-card__button"
                onClick={() => {
                    actionSwitcher({
                        actionType: ACTION.PAY,
                        ...props.contractor,
                        fullName: _fullName,
                    });
                }}
            >
                Сформировать акт
            </NmButton>
        );
    };

    const renderActionButtonByTabType = (media) => {
        const {
            RESPONSES,
            INVITED,
            PRE_APPROVED,
            SIGN_AGREEMENT,
            HIRED,
            REFUSALS,
        } = ORDER_CARD_PERFORMERS_TABS;

        const isMobile = media.mobile || media.tablet;

        const _fullName = lastName ? getFullName(lastName, firstName, patronymic) : fullName;

        const isShowCloseInviteBtn = [TAB_TYPE_BY_SUB_PAGE[INVITED].LINK, TAB_TYPE_BY_SUB_PAGE[SIGN_AGREEMENT].LINK].includes(subpage)
            && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)
            && orderSource !== ORDER_SOURCE_TYPES.REGISTRY;

        if (isShowCloseInviteBtn) {
            return (
                <>
                    <NmButton
                        color="white"
                        size="lg"
                        className="order-contractors-card__button"
                        onClick={() => {
                            actionSwitcher({
                                actionType: ACTION.REJECT,
                                ...props.contractor,
                                fullName: _fullName,
                            });
                        }}
                    >
                        Отозвать приглашение
                    </NmButton>
                    {
                        !isMobile &&
                        <ContextMenu
                            className="order-contractors-card__action"
                            options={[
                                {
                                    text: pin ? "Открепить с заказа" : "Закрепить на заказе",
                                    key: "attachToOrder",
                                    value: "attachToOrder",
                                },
                            ]}
                            onClickItem={({value: actionType}) => {
                                actionSwitcher({
                                    actionType,
                                    ...props.contractor,
                                });
                            }}
                        />
                    }
                    {getDropdown(media)}
                </>
            );
        }

        if ([TAB_TYPE_BY_SUB_PAGE[PRE_APPROVED].LINK].includes(subpage) && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)) {
            return (
                <>
                    <NmButton
                        color="white"
                        size="lg"
                        className="order-contractors-card__button"
                        onClick={() => {
                            actionSwitcher({
                                actionType: ACTION.DECLINE,
                                ...props.contractor,
                                fullName: _fullName,
                            });
                        }}
                    >
                        Отказать
                    </NmButton>
                    {getDropdown(media)}
                </>
            );
        }

        if ([TAB_TYPE_BY_SUB_PAGE[HIRED].LINK].includes(subpage)) {
            return (
                <>
                    {getPayButton()}
                    {getDropdown(media)}
                </>
            );
        }

        if (
            isVisibleResponsesActions
            && subpage === TAB_TYPE_BY_SUB_PAGE[RESPONSES].LINK
            && ![NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(role)
        ) {
            return (
                <>
                    <NmButton
                        className="order-contractors-card__button order-contractors-card__button-approve"
                        color="light-green"
                        size="lg"
                        onClick={(action) => {
                            actionSwitcher({
                                ...action,
                                contractorId: action.key,
                                ...props.contractor,
                                fullName: _fullName,
                                actionType: ACTION.APPROVE,
                            });
                        }}
                    >
                        Одобрить
                    </NmButton>
                    <NmButton
                        className="order-contractors-card__button"
                        color="grey"
                        size="lg"
                        onClick={(action) => {
                            actionSwitcher({
                                ...action,
                                contractorId: action.key,
                                ...props.contractor,
                                fullName: _fullName,
                                actionType: ACTION.DECLINE,
                            });
                        }}
                    >
                        Отказать
                    </NmButton>
                    {getDropdown(media)}
                </>
            );
        }

        if ([TAB_TYPE_BY_SUB_PAGE[REFUSALS].LINK].includes(subpage)) {
            return getDropdown(media);
        }

        return null;
    };

    const isShowChatButton = ({contractorStatus, orderManagerId}) => {
        const currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

        if ((role === FOREMAN && currentClientUserId !== orderManagerId) || [ORDER_SOURCE_TYPES.REGISTRY, ORDER_SOURCE_TYPES.API].includes(orderSource)) {
            return false;
        }

        const {
            AWAITING_CONTRACTOR_CONFIRMATION,
            AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING,
            AWAITING_CLIENT_CONFIRMATION,
            CLIENT_PREAPPROVED,
            HIRED,
            AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION,
            AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION,
            NEGOTIATIONS_ON_THE_DISPUTE,
            COMPLETED,
            BLOCKED,
        } = STATUS;

        return [AWAITING_CONTRACTOR_CONFIRMATION, AWAITING_CONTRACTOR_FRAME_CONTRACT_SIGNING, AWAITING_CLIENT_CONFIRMATION,
            CLIENT_PREAPPROVED, HIRED, AWAITING_CONTRACTOR_ORDER_COMPLETION_CONFIRMATION, AWAITING_CLIENT_ORDER_COMPLETION_CONFIRMATION, NEGOTIATIONS_ON_THE_DISPUTE, COMPLETED, BLOCKED].includes(contractorStatus);
    };

    const renderActions = (matches) => {
        const {REFUSALS} = ORDER_CARD_PERFORMERS_TABS;

        return (
            <div className="order-contractors-card__actions">
                {
                    matches.desktop && phone &&
                    <NmAdvancedTooltip
                        className="order-contractors-card__icon-btn"
                        hover
                        position="bottom-right"
                        trigger={
                            <NmButton
                                onlyIcon
                                color="grey"
                                reactMaterialIcon="local_phone"
                                onClick={() => {
                                    handleCopyContact(phoneFormatWithoutSevenNumber(phone));
                                }}
                            />
                        }
                    >
                        {phoneFormat(phone)}
                    </NmAdvancedTooltip>
                }
                {
                    matches.desktop && subpage !== TAB_TYPE_BY_SUB_PAGE[REFUSALS].LINK &&
                    isShowChatButton({contractorStatus, orderManagerId}) &&
                    <NmAdvancedTooltip
                        className="order-contractors-card__icon-btn"
                        hover
                        position="bottom-right"
                        trigger={
                            <NmButton
                                onlyIcon
                                color="grey"
                                reactMaterialIcon="chat"
                                popup="Перейти в чат"
                                onClick={showChatList(chatId, contractorId, orderId, clientId)}
                            />
                        }
                    >
                        Перейти в чат
                    </NmAdvancedTooltip>
                }
                {renderActionButtonByTabType(matches)}
            </div>
        );
    };

    const getStatusDate = () => {
        return (
            <div className="order-contractors-card__application-date">
                <div className="d-flex order-contractors-card__row-item">
                    <div className="order-contractors-card__label order-contractors-card__label_application-date">
                        {getLabelStatusDateTextByTab(subpage, applicationType)}
                    </div>
                    <div className="order-contractors-card__text">
                        {formatLocalDate(applicationDate, "dd.MM.yyyy HH:mm")}
                    </div>
                </div>
                {
                    subpage === ORDER_CARD_PERFORMERS_TABS.REFUSALS &&
                    <div className="d-flex order-contractors-card__row-item">
                        <div className="order-contractors-card__label order-contractors-card__label_application-date">
                            Дата отказа:
                        </div>
                        <div className="order-contractors-card__text">
                            {formatLocalDate(refusedDate, "dd.MM.yyyy HH:mm")}
                        </div>
                    </div>
                }
            </div>
        );
    };

    const renderFcStatus = () => {
        const isFcExist = !isNullOrWhitespace(contractDate);

        return (subpage !== ORDER_CARD_PERFORMERS_TABS.SIGN_AGREEMENT &&
            <NmAdvancedTooltip
                className="order-contractors-card__item order-contractors-card__checks-row-item"
                position="bottom-left"
                children={isFcExist ? "С исполнителем заключен рамочный договор" : "Рамочный договор с исполнителем отсутствует"}
                trigger={
                    <NmCheck
                        isCheck={isFcExist}
                        label={isFcExist ? `РД ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]} от ${formatDate(convertUtcToLocal(contractDate), "dd.MM.yyyy")}` : `РД ${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]}`}
                    />
                }
                hover
            />
        );
    };

    return (
        <div className="order-contractors-card gx-0 mb-2 row">
            {
                notification &&
                <div className="order-contractors-card__notification">
                    <div className="order-contractors-card__notification-message">
                        {notification}
                    </div>
                </div>
            }
            <div className="col-md-16 d-flex align-items-start order-contractors-card__content">
                <div className="order-contractors-card__avatar me-4">
                    <Avatar
                        addedToMyClientGroup={contractor.addedToMyClientGroup}
                        contractorId={contractorId}
                        avatarPresent={contractor.avatarPresent}
                    />
                </div>
                <div className="order-contractors-card__main-content">
                    {renderStatus()}
                    <div className="order-contractors-card__row order-contractors-card__contractor-container">
                        <div className="order-contractors-card__contractor-name">
                            <Link
                                className="order-contractors-card__contractor-link"
                                to={LINK_CONTRACTOR_PROFILE.replace(":contractorId", contractorId)}
                            >
                                {_fullName}
                            </Link>
                            {pin && <AttachedLogo color={COLOR.PASSIVE_50} />}
                            {blacklist &&
                                <NmAdvancedTooltip
                                    hover
                                    position="top"
                                    children={blacklistReason}
                                    className="order-contractors-card__tooltip"
                                    trigger={
                                        <IconBlacklist
                                            className="order-contractors-card__blacklist"
                                        />
                                    }
                                />
                            }
                            {blocked &&
                                <NmAdvancedTooltip
                                    hover
                                    position="top"
                                    children={blockedReason}
                                    className="order-contractors-card__tooltip"
                                    trigger={
                                        <NmIcon
                                            className="order-contractors-card__icon-blocked"
                                            name="blocked"
                                        />
                                    }
                                />
                            }
                            <ContractorNoteForClientTooltip
                                className="ms-2"
                                note={noteForClient}
                            />
                        </div>
                        <NmReadonlyRating
                            className="order-contractors-card__rating order-contractors-card__rating_mobile"
                            rating={overallRating || 0}
                        />
                    </div>
                    {getStatusDate()}
                    <div className="order-contractors-card__row order-contractors-card__checks-row">
                        <NmReadonlyRating
                            className="order-contractors-card__rating order-contractors-card__rating_desktop"
                            rating={overallRating || 0}
                        />
                        {
                            isShowOrderInfo &&
                            <div
                                className="d-flex align-items-center order-contractors-card__item order-contractors-card__checks-row-item"
                            >
                                <NmCheck
                                    isCheck={smzTaxOffer}
                                    label="Автоматическая уплата налогов"
                                />
                            </div>
                        }
                        <div
                            className="d-flex align-items-center order-contractors-card__item order-contractors-card__checks-row-item"
                        >
                            {
                                isIndividualEntrepreneur ?
                                    <ContractorIndividualEntrepreneurIndicator
                                        status={individualEntrepreneurStatus}
                                        statusDate={individualEntrepreneurRegistrationIssuedDateTime}
                                    /> :
                                    <SelfEmployedIndicator taxStatus={taxStatus} />
                            }
                        </div>
                        <div className="d-flex order-contractors-card__item order-contractors-card__checks-row-item">
                            <NmAdvancedTooltip
                                hover
                                position="bottom-left"
                                trigger={
                                    <NmCheck
                                        isCheck={isPassportSuccess}
                                        label={
                                            <div
                                                className="order-contractors-card__text order-contractors-card__text_no-margin-left"
                                            >
                                                Паспорт проверен
                                            </div>
                                        }
                                    />
                                }
                                children={isPassportSuccess ? passportSuccessText : passportErrorText}
                            />
                        </div>
                        {
                            Boolean(orderCompletedCount || currentClientOrderCompletedCount) &&
                            <div
                                className="d-flex align-items-center order-contractors-card__item order-contractors-card__checks-row-item"
                            >
                                <NmAdvancedTooltip
                                    position="bottom-left"
                                    children={t("contractor-list.completed-orders-help")}
                                    trigger={
                                        <NmCheck
                                            isCheck={true}
                                            label={
                                                <div
                                                    className="order-contractors-card__text order-contractors-card__text_no-margin-left"
                                                >
                                                    {`${t("contractor-list.completed-orders")} ${orderCompletedCount || 0}/${currentClientOrderCompletedCount || 0}`}
                                                </div>
                                            }
                                        />
                                    }
                                    hover
                                />
                            </div>
                        }
                        {
                            !isClientUser(role) &&
                            !isIndividualEntrepreneur &&
                            <div
                                className="d-flex align-items-center order-contractors-card__item order-contractors-card__checks-row-item"
                            >
                                <BankIndicator
                                    hideLoaderContent
                                    level="2"
                                    size="lg"
                                    contractorsPossibility={contractorsPossibility}
                                    contractorId={contractorId}
                                    showLabel
                                />
                            </div>
                        }
                        <div
                            className="d-flex align-items-center order-contractors-card__item order-contractors-card__checks-row-item"
                        >
                            <InstrumentPaymentIndicator
                                level="2"
                                size="lg"
                                value={
                                    isIndividualEntrepreneur
                                        ? individualEntrepreneurBankRequisitesPresent
                                        : hasPaymentMethodCard
                                }
                                isIndividualEntrepreneur={isIndividualEntrepreneur}
                            />
                        </div>
                        {renderFcStatus()}
                    </div>
                    <div className="order-contractors-card__row align-items-center">
                        <div className="d-flex order-contractors-card__row-item">
                            <div className="order-contractors-card__label">
                                Возраст:
                            </div>
                            <div
                                className="order-contractors-card__text order-contractors-card__text_bold"
                            >
                                {age && pluralizeYears(age)}
                            </div>
                        </div>
                        <div className="d-flex order-contractors-card__row-item flex align-items-center">
                            <div className="order-contractors-card__label">
                                Гражданство:
                            </div>
                            <div
                                className="order-contractors-card__text order-contractors-card__text_bold flex align-items-center"
                            >
                                {contractorCitizenship}
                                {
                                    locatedOutsideRussia &&
                                    <LocatedOutsideRussiaTooltip />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="order-contractors-card__row order-contractors-card__row-item">
                        {
                            !isEmpty(workAddressInfo) &&
                            <NmSelectedList
                                showedItemsCount={3}
                                highlightingFirstItem
                                showListWithoutValue
                                list={workAddressInfo}
                                label="Города ведения деятельности"
                                dropdownWidth={250}
                            />
                        }
                    </div>
                    {
                        subpage === ORDER_CARD_PERFORMERS_TABS.HIRED &&
                        <div className="order-contractors-card__row">
                            <div className="d-flex order-contractors-card__row-item">
                                <div className="order-contractors-card__label">
                                    Дата последней отправки акта (дата
                                    акта):
                                </div>
                                <div className="order-contractors-card__text order-contractors-card__text_bold">
                                    {
                                        !lastPaymentDate && !actDate ? "-" :
                                            `${formatDate(convertUtcToLocal(lastPaymentDate), "dd.MM.yyyy") || "-"} (${formatDate(convertUtcToLocal(actDate), "dd.MM.yyyy") || "-"})`
                                    }
                                </div>
                            </div>
                            <div className="d-flex order-contractors-card__row-item">
                                <div className="order-contractors-card__label">
                                    Сумма:
                                </div>
                                <div
                                    className="order-contractors-card__text order-contractors-card__text_bold"
                                >
                                    {formatAmount(formatNumber(lastPaymentSum)) || "-"}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isShowOrderInfo && orderNum &&
                        <div className="order-contractors-card__row order-contractors-card__row_unset">
                            <div className="order-contractors-card__label">
                                Заказ:
                            </div>
                            <span
                                onClick={goOrder}
                                className="order-contractors-card__text-link"
                            >
                                {` ${orderNum} ${orderName}`}
                            </span>
                        </div>
                    }
                    {
                        isShowOrderInfo && projectId &&
                        <div className="order-contractors-card__row order-contractors-card__row_unset">
                            <div className="order-contractors-card__label">
                                Проект:
                            </div>
                            <span
                                onClick={goProject}
                                className="order-contractors-card__text-link"
                            >
                                {projectName}
                            </span>
                        </div>
                    }
                    {
                        isShowOrderInfo && objectAddress &&
                        <div className="order-contractors-card__row order-contractors-card__row_unset">
                            <div className="order-contractors-card__label">
                                Объект:
                            </div>
                            <div className="order-contractors-card__text">
                                {objectAddress}
                            </div>
                        </div>
                    }
                    <div className="order-contractors-card__actions-container">
                        <Media
                            queries={{
                                mobile: "(max-width: 767px)",
                                tablet: "(min-width: 768px) and (max-width: 1919px)",
                                desktop: "(min-width: 1920px)",
                            }}
                        >
                            {
                                matches => renderActions(matches)
                            }
                        </Media>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderContractorsCard;