import React, {useContext} from "react";
import {useDispatch} from "react-redux";
import {isEmpty} from "lodash";

import {
    deleteRecruitmentVacancyCandidatesResume,
} from "../../../../../ducks/bff/recruitment/vacancyCandidates/actionCreators";
import Box from "../../../../ActualComponents/Box";
import ContextMenu from "../../../../ActualComponents/ContextMenu";
import NmDivider from "../../../../ActualComponents/NmDivider";
import NmMiniInfoCard from "../../../../ActualComponents/NmMiniInfoCard";
import {
    RecruitmentCandidateInformationContext,
} from "../../../InformationCard";
import {RecruitmentCandidateInformationDeletingConfirm} from "../DeletingConfirm";
import {RecruitmentCandidateResumeLink} from "../ResumeLink";
import {RecruitmentCandidateResumesEdit} from "./Edit";

import {useModal} from "../../../../../hooks/useModal";

import {getClassNames} from "../../../../../utils/classNames";
import formatDate, {convertUtcToLocal} from "../../../../../utils/dateFormat";
import {isLastElementInArray} from "../../../../../utils/objectHelper";
import {getCandidateInformationEditAction} from "../../utils/getEditAction";

import {COLOR} from "../../../../../constants/color";
import {RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE} from "../../../../../containers/recruitment/vacancies/constants";

export const RecruitmentCandidateResumes = (props) => {
    const {
        isAccessActions,
        jobBoardsResumes,
        otherResumes,
    } = props;

    const {
        fetchInformation,
        candidateId,
        card,
    } = useContext(RecruitmentCandidateInformationContext);

    const dispatch = useDispatch();

    const {
        isOpen: isOpenDeleteConfirm,
        modalData: confirmDeleteData,
        onOpenModal: onOpenDeleteConfirm,
        onCloseModal: onCloseDeleteConfirm,
    } = useModal();

    const {
        isOpen: isOpenEdit,
        onOpenModal: onOpenEdit,
        onCloseModal: onCloseEdit,
    } = useModal();

    const onDelete = () => {
        dispatch(deleteRecruitmentVacancyCandidatesResume({
            id: confirmDeleteData.id,
            candidateId: card.candidateId,
            fileName: confirmDeleteData.fileName,
            onSuccess: () => {
                onCloseDeleteConfirm();
                fetchInformation();
            },
        }));
    };

    return (
        <>
            {
                isOpenEdit &&
                <RecruitmentCandidateResumesEdit
                    onClose={onCloseEdit}
                    candidateId={candidateId}
                />
            }
            {
                isOpenDeleteConfirm &&
                <RecruitmentCandidateInformationDeletingConfirm
                    onCancel={onCloseDeleteConfirm}
                    onConfirm={onDelete}
                />
            }
            <NmMiniInfoCard
                title="Резюме"
                isHeaderDivider={false}
                titleLevel="4"
                onClickAdd={() => {
                    onOpenEdit({});
                }}
                accessEdit={
                    isAccessActions && otherResumes.length < 5
                }
            >
                {
                    isEmpty(otherResumes) && isEmpty(jobBoardsResumes) ?
                        "-" :
                        null
                }
                {
                    !isEmpty(jobBoardsResumes) ?
                        <Box
                            theme="page"
                            className="mb-4"
                            backgroundColor={COLOR.SECONDARY_5}
                            noBorder={true}
                        >
                            {
                                jobBoardsResumes.map((item, index) => {
                                    const isLast = isLastElementInArray(jobBoardsResumes, index);
                                    const name = RECRUITMENT_SOURCE_JOB_BOARD_TRANSLATE[item.type];
                                    const updateDateTime = formatDate(convertUtcToLocal(item.updateDateTime), "dd.MM.yy");

                                    return (
                                        <RecruitmentCandidateResumeLink
                                            isDownload={false}
                                            className={getClassNames([
                                                !isLast && "mb-4",
                                            ])}
                                            type={item.type}
                                            fileName={item.fileName}
                                            candidateId={card.candidateId}
                                            isName={true}
                                            originalUrl={item.originalUrl}
                                            name={`${name} ${updateDateTime}`}
                                        />
                                    );
                                })
                            }
                        </Box> :
                        null
                }
                {
                    !isEmpty(otherResumes) ?
                        otherResumes.map((item, index) => {
                            const isLast = isLastElementInArray(otherResumes, index);

                            return (
                                <React.Fragment key={item.type}>
                                    <div className="d-flex justify-content-between align-items-center align-items-xxl-center">
                                        <RecruitmentCandidateResumeLink
                                            isDownload={false}
                                            type={item.type}
                                            fileName={item.fileName}
                                            candidateId={card.candidateId}
                                            isName={true}
                                            name={item.fileName}
                                            comment={item.comment}
                                        />
                                        {
                                            isAccessActions &&
                                            <ContextMenu
                                                notBorder
                                                horizontalIcon
                                                disabled={false}
                                                clearWidth
                                                className="ms-1"
                                                options={getCandidateInformationEditAction({
                                                    onClickDelete: () => {
                                                        onOpenDeleteConfirm(item);
                                                    },
                                                })}
                                            />
                                        }
                                    </div>
                                    {
                                        !isLast &&
                                        <NmDivider
                                            className="my-2"
                                            color={COLOR.SECONDARY_5}
                                        />
                                    }
                                </React.Fragment>
                            );
                        }) :
                        null
                }
            </NmMiniInfoCard>
        </>
    );
};
