import {createSelector} from "reselect";

import {getUserRole} from "../../../../../utils/access";
import {getTotalPages} from "../../../../../utils/mathHelper";

import {LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES} from "../../../../../constants/links";
import {RECRUITER} from "../../../../../constants/roles";

export const bffRecruitmentDirectorySelector = state => state.bff.recruitment.accessControl.observers;

export const bffRecruitmentAccessControlObserversProgressSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({progress}) => progress,
);
export const bffRecruitmentAccessControlObserversProgressActionSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({progressAction}) => progressAction,
);

const observerListSelector = ({list}, seniorRecruiter) => {
    return list.map(item => {

        return {
            ...item,
            vacanciesList: getModalVacanciesList(item.vacancies, seniorRecruiter),
        };
    });
};

// Для отображения в списке в модальном окне
export const getModalVacanciesList = (list, seniorRecruiter) => {
    const role = getUserRole();
    const isText = role === RECRUITER && !seniorRecruiter;

    return list?.map(item => {
        return {
            vacancyId: item.vacancyId,
            text: `№${item.vacancyNumber} ${item.title}`,
            link: isText
                ? undefined
                : LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES
                    .replace(":vacancyId", item.vacancyId)
                    .replace(":clientId", item.clientId),
        };
    });
};

export const bffRecruitmentAccessControlObserverListSelector = createSelector(
    [
        bffRecruitmentDirectorySelector,
        (_state, seniorRecruiter) => seniorRecruiter,
    ],
    observerListSelector,
);
export const bffRecruitmentAccessControlObserverTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({totalCount}) => totalCount,
);
export const bffRecruitmentAccessControlObserverTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({totalCount, pageData: {pageSize = 0}}) => getTotalPages(totalCount, pageSize),
);

export const bffRecruitmentAccessControlObserverVacanciesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({vacancies}) => {
        return vacancies.map(item => {
            return {
                ...item,
                name: `№${item.vacancyNumber} - ${item.title}`,
                showCheckBox: true,
                isShowChevronButton: false,
                isSelected: typeof item.isSelected === "boolean" ? item.isSelected : item.hasAssigned,
            };
        });
    },
);
export const bffRecruitmentAccessControlObserverVacanciesTotalCountSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({vacanciesTotalCount}) => vacanciesTotalCount,
);
export const bffRecruitmentAccessControlObserverVacanciesTotalPagesSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({vacanciesTotalCount, vacanciesPageData: {pageSize = 0}}) => {
        return getTotalPages(vacanciesTotalCount, pageSize);
    },
);
export const bffRecruitmentAccessControlObserverVacanciesProgressSelector = createSelector(
    bffRecruitmentDirectorySelector,
    ({observersProgress}) => observersProgress,
);