import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import CheckboxAccordionList from "../../../../../../components/ActualComponents/CheckboxAccordionList";
import NmForm from "../../../../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../../../../components/ActualComponents/NmInputV2";
import NmModal from "../../../../../../components/ActualComponents/NmModal";
import NmPagination from "../../../../../../components/ActualComponents/NmPagination";
import ApplyButtons from "../../../../../../components/ApplyButtons";
import NmButton from "../../../../../../components/NmButton";
import NmTitle from "../../../../../../components/NmTitle";
import TableSelectedLabel from "../../../../../../components/TableSelectedLabel";
import {
    addRecruitmentAccessControlObserver,
    getRecruitmentAccessControlVacancies,
    updateRecruitmentAccessControlObserver,
    updateRecruitmentAccessControlObserverStore,
} from "../../../../../../ducks/bff/recruitment/access-control/observers/actionCreators";
import {
    bffRecruitmentAccessControlObserversProgressActionSelector,
    bffRecruitmentAccessControlObserverVacanciesProgressSelector,
    bffRecruitmentAccessControlObserverVacanciesSelector,
    bffRecruitmentAccessControlObserverVacanciesTotalCountSelector,
    bffRecruitmentAccessControlObserverVacanciesTotalPagesSelector,
} from "../../../../../../ducks/bff/recruitment/access-control/observers/selectors";

import {useDebounceFetch} from "../../../../../../hooks/useDebounceFetch";
import {usePagination} from "../../../../../../hooks/usePagination";

import {filterEmptyValues} from "../../../../../../utils/objectHelper";

import "./style.sass";

export const RecruitmentAccessControlObserverEdit = (props) => {
    const {
        onClose,
        id,
        clientId,
        clientUserId,
        fetchList,
    } = props;

    const dispatch = useDispatch();

    const progressAction = useSelector(bffRecruitmentAccessControlObserversProgressActionSelector);
    const totalCount = useSelector(bffRecruitmentAccessControlObserverVacanciesTotalCountSelector);
    const totalPages = useSelector(bffRecruitmentAccessControlObserverVacanciesTotalPagesSelector);
    const progress = useSelector(bffRecruitmentAccessControlObserverVacanciesProgressSelector);
    const list = useSelector(bffRecruitmentAccessControlObserverVacanciesSelector);

    const selectedCount = useMemo(() => {
        return list.filter(value => value.isSelected).length;
    }, [
        list,
    ]);

    const {
        pageNum,
        pageSize,
        setPagination,
        onPaginationChange,
    } = usePagination();

    const {
        setValueFilter: setVacancyName,
        valueFilter: vacancyName,
    } = useDebounceFetch({
        fetchCallback: () => {
            if (pageNum !== 1) {
                setPagination({
                    pageSize,
                    pageNum: 1,
                });
            }

            fetchPage({
                pageNum: 1,
            });
        },
        isMountLoad: false,
    });

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentAccessControlObserverStore({
                vacancies: [],
                vacanciesTotalCount: 0,
                vacanciesPageData: {},
            }));
        };
    }, []);

    useEffect(() => {
        fetchPage({
            pageNum,
        });
    }, [
        pageNum,
    ]);

    function fetchPage({pageNum}) {
        const requestData = filterEmptyValues({
            id,
            clientId,
            clientUserId,
            title: vacancyName,
            pageNum,
            pageSize,
            titleNumberCombinedSearch: true,
        });

        dispatch(getRecruitmentAccessControlVacancies(requestData));
    }

    const onSubmit = () => {
        const requestData = {
            id,
            clientId,
            clientUserId,
            vacanciesIds: list
                .filter(item => item.isSelected)
                .map(item => item.vacancyId),
            onSuccess: () => {
                onClose();
                fetchList();
            },
        };

        if (!id) {
            dispatch(addRecruitmentAccessControlObserver(requestData));

            return;
        }

        dispatch(updateRecruitmentAccessControlObserver(requestData));
    };

    const getFilter = () => {
        return (
            <div className="d-flex flex-column flex-md-row align-items-md-end">
                <NmInputV2
                    size="xl"
                    label="Вакансии для просмотра"
                    placeholder="Введите номер или название вакансии"
                    value={vacancyName}
                    maxLength={255}
                    onChange={(event, {value}) => {
                        setVacancyName(value);
                    }}
                />
                <NmButton
                    size="xl"
                    className="ms-0 mt-2 mt-md-0 ms-md-4"
                    color="grey"
                    onClick={() => {
                        setVacancyName("");
                    }}
                >
                    Очистить
                </NmButton>
            </div>
        );
    };

    const onSelectedRows = (list) => {
        dispatch(updateRecruitmentAccessControlObserverStore({
            vacancies: list,
        }));
    };

    const getList = () => {
        return (
            <CheckboxAccordionList
                classNameContentContainer="recruitment-observer-edit__list"
                allCheckboxLabel={<TableSelectedLabel count={selectedCount} />}
                onSelectedRows={onSelectedRows}
                list={list}
            />
        );
    };

    return (
        <NmModal
            size="md"
            onClose={onClose}
            header={
                <NmTitle size="lg">
                    Редактирование вакансий наблюдателю
                </NmTitle>
            }
            footer={
                <ApplyButtons
                    mobile="column"
                    isHiddenCancelOnMobile
                    submit={onSubmit}
                    onClose={onClose}
                    disabled={progressAction}
                    submitBtnContent="Сохранить"
                    cancelBtnContent="Отменить"
                />
            }
            loading={progress}
        >
            <NmForm addMargin={true}>
                {getFilter()}
                {getList()}
                <NmPagination
                    className="mt-2 mt-md-6 mt-md-8"
                    totalCount={totalCount}
                    pageNum={pageNum}
                    totalPages={totalPages}
                    pageSize={pageSize}
                    isHasPageSize={false}
                    onChangePagination={onPaginationChange}
                />
            </NmForm>
        </NmModal>
    );
};