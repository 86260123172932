import React, {ReactNode} from "react";

import bem from "../../../../utils/bem";
import {getClassNames} from "../../../../utils/classNames";

import "./style.sass";

export type TNmModalCardValues = [{label: string, value: string}];
export type TNmModalCardSingleValues = [{label: string, value: string}];
export type TNmModalAdditionalTitleValue = string | ReactNode;

export interface INmModalCard {
    values?: Array<{label: string, value: string}>,
    singleValues: Array<{label: string, value?: string}>,
    title?: string,
    additionalTitleValue?: string | ReactNode,
    className?: string,
    icon?: ReactNode,
    mainContent?: ReactNode,
    classNameMainContent?: string,
    actions?: ReactNode | null
}

const NmModalCard = (props: INmModalCard) => {
    const {
        values,
        singleValues,
        title,
        additionalTitleValue,
        actions,
        icon,
        className = "",
        mainContent,
        classNameMainContent,
    } = props;
    const [block, element] = bem("nm-modal-card", className);

    return (
        <div className={block()}>
            {
                icon &&
                <div className={element("icon")}>
                    {icon}
                </div>
            }
            <div
                className={getClassNames([
                    element("main-content"),
                    classNameMainContent,
                ])}
            >
                {mainContent}
                {
                    title &&
                    <div className={element("title")}>
                        <div className={element("title-value")}>
                            {title}
                        </div>
                        <div className={element("title-additional")}>
                            {additionalTitleValue}
                        </div>
                    </div>
                }
                {
                    values &&
                    <div className={element("row")}>
                        {
                            values.map(({label, value}) =>
                                <div className={element("block")}>
                                    <div className={element("label")}>
                                        {label}
                                        :
                                    </div>
                                    <div className={element("value")}>
                                        {value}
                                    </div>
                                </div>,
                            )
                        }
                    </div>
                }
                {
                    singleValues &&
                    <div className={element("column")}>
                        {
                            singleValues.map(({label, value}, index) =>
                                <div
                                    key={`${value}${index}`}
                                    className={element("single-block")}
                                >
                                    {
                                        label &&
                                        <div className={element("label")}>
                                            {label}
                                            :
                                        </div>
                                    }
                                    {
                                        value &&
                                        <div className={element("value")}>
                                            {value}
                                        </div>
                                    }
                                </div>,
                            )
                        }
                    </div>
                }
            </div>
            {
                actions &&
                <div className={element("actions")}>
                    {actions}
                </div>
            }
        </div>
    );
};

export default NmModalCard;