export const BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR = "BFF_RECRUITMENT_DIRECTORY_STATUS_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST = "BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR = "BFF_RECRUITMENT_DIRECTORY_STATUS_ADD_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR = "BFF_RECRUITMENT_DIRECTORY_STATUS_UPDATE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR = "BFF_RECRUITMENT_DIRECTORY_STATUS_DELETE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_ADD_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_UPDATE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR = "BFF_RECRUITMENT_DIRECTORY_JOB_REQUIREMENT_DELETE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_GET_PAGE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_ADD_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_UPDATE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR = "BFF_RECRUITMENT_DIRECTORY_FUNNEL_DELETE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST = "BFF_RECRUITMENT_DIRECTORY_COUNTS_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_COUNTS_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR = "BFF_RECRUITMENT_DIRECTORY_COUNTS_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_PROFESSIONS_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_SCHEDULES_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_EXPERIENCES_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_BILLING_TYPES_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_BUSINESS_AREAS_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST = "BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR = "BFF_RECRUITMENT_DIRECTORY_AVITO_ACTUAL_BUSINESS_AREAS_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SEARCH_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_EDIT_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_REQUEST";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_SUCCESS";
export const BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR = "BFF_RECRUITMENT_DIRECTORY_REJECTION_REASON_DELETE_ERROR";

export const BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE = "BFF_RECRUITMENT_DIRECTORY_UPDATE_STORE";