import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {
    getRecruitmentAccessControlObserverList,
    updateRecruitmentAccessControlObserverStore,
} from "../../../../../ducks/bff/recruitment/access-control/observers/actionCreators";

import {filterEmptyValues} from "../../../../../utils/objectHelper";
import {removePhoneMask} from "../../../../../utils/stringFormat";

export const useRecruitmentDirectoriesObserverFetch = (props) => {
    const {
        clientId,
        pageNum,
        pageSize,
        filterData,
    } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(updateRecruitmentAccessControlObserverStore({
                list: [],
                pageData: {},
                totalCount: 0,
            }));
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [
        pageNum,
        pageSize,
        filterData,
    ]);

    const fetchList = () => {
        const requestData = filterEmptyValues({
            clientId,
            pageNum,
            pageSize,
            ...filterData,
            phone: filterData.phone ? removePhoneMask(filterData.phone) : null,
            vacancyNumber: filterData.vacancyNumber ? +filterData.vacancyNumber : null,
        });

        dispatch(getRecruitmentAccessControlObserverList(requestData));
    };

    return {
        fetchList,
    };
};