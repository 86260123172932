/* CONTRACTOR LINKS */
import {BRIGADE_LIST, BRIGADE_ORDER_OBJECT_OBJECT, BRIGADE_PAYMENTS, BRIGADE_TIME_SHEET} from "./link-params";

export const LOCAL_PROD_NAIMIX_INFO_LINK = "https://prod.naimix.info";
export const NAIMIX_INFO_LINK = "https://naimix.info";
export const APP_NAIMIX_INFO_LINK = "https://app.naimix.info";
export const TEST_NAIMIX_INFO_LINK = "https://nm-test.mmtr.ru";
export const PREDPROD_NAIMIX_INFO_LINK = "https://nm-predprod.mmtr.ru";
export const DEVELOP_NAIMIX_LINK = "https://nm.mmtr.ru";
export const DEFAULT_LOCAL_HOST = "http://localhost:3000";
export const LINK_OPEN_NAIMIX_PREDPROD = "https://nm-open.mmtr.ru";

export const TASK_LIST_LINK = "/tasks";

export const LINK_CONTRACTOR_LIST = "/contractor-list",
    LINK_CONTRACTOR_CARD = "/contractor-card/:contractorId",
    LINK_CONTRACTOR_PERSONAL_INFO = `${LINK_CONTRACTOR_CARD}/personal-info`,
    LINK_CONTRACTOR_CHECKING_ADDITIONAL_DOCUMENTS = `${LINK_CONTRACTOR_CARD}/checking-additional-documents`,
    LINK_CONTRACTOR_DOCUMENT_LIST = `${LINK_CONTRACTOR_CARD}/document-list`,
    LINK_CONTRACTOR_PROFILE = `${LINK_CONTRACTOR_CARD}/profile`,
    LINK_CONTRACTOR_ORDER_LIST = `${LINK_CONTRACTOR_CARD}/order-list`,
    LINK_CONTRACTOR_TASK_LIST = `${LINK_CONTRACTOR_CARD}/task-list`,
    LINK_CONTRACTOR_CARD_FINANCE_PATENTS_LIST = `${LINK_CONTRACTOR_CARD}/finance/patents`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_BALANCE_LIST = `${LINK_CONTRACTOR_CARD}/finance/balance`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_ORDERS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/completed/orders`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_ORDERS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/in-process/orders`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_ORDERS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/awaiting/orders`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_COMPLETED_LIST_TASKS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/completed/tasks`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_IN_PROCESS_LIST_TASKS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/in-process/tasks`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NPD_AWAITING_LIST_TASKS = `${LINK_CONTRACTOR_CARD}/finance/income-npd/awaiting/tasks`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_COMPLETED_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-ndfl/completed`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_IN_PROCESS_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-ndfl/in-process`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_NDFL_AWAITING_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-ndfl/awaiting`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_COMPLETED_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-individual/completed`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_IN_PROCESS_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-individual/in-process`,
    LINK_CONTRACTOR_CARD_FINANCE_INCOME_INDIVIDUAL_AWAITING_LIST = `${LINK_CONTRACTOR_CARD}/finance/income-individual/awaiting`,
    LINK_CONTRACTOR_DEVICE_LIST = `${LINK_CONTRACTOR_CARD}/device-list`,
    LINK_CONTRACTOR_VERIFICATION_DATA = "/contractor-verification-data/:contractorId",
    LINK_CONTRACTOR_REVIEWS_LIST = `${LINK_CONTRACTOR_CARD}/reviews-list`,
    LINK_CONTRACTOR_BANKS_LIST = `${LINK_CONTRACTOR_CARD}/banks`;

export const LINK_DOWNLOAD_PRIVACY_POLICY = "/files/политика-ПД.pdf";
export const LINK_DOWNLOAD_AGREEMENT = "/files/Правила-ЭП.pdf";
export const LINK_DOWNLOAD_XML_NAIMIX_1C_POLICY = "/files/ЗагрузкаXMLНаймикс_ver5.epf";
export const LINK_DOWNLOAD_XML_NAIMIX_1C_REPORT = "/files/ЗагрузкаXMLНаймикс_ver27.epf";

export const KEDO_LOAD_DOCUMENT_TYPES = "/load-document-types"; // Типы загруженных документов
export const KEDO_TEMPLATE_DOCUMENT_TYPES = "/template-document-types"; // Типы шаблонных документов
export const KEDO_POSITIONS = "/positions"; // Должности
export const KEDO_SUBDIVISIONS = "/subdivisions"; // Структурные подразделения

export const
    LINK_HOME = "/",
    LINK_LOGIN = "/login",
    LINK_REQUEST = "/request",
    LINK_FORBIDDEN_PAGE = "/forbidden",
    LINK_CHANGE_PASSWORD = "/change-password/:token",
    LINK_CLIENT_LIST = "/client",
    LINK_OPERATOR_REPORTS = "/document-operator-reports",
    LINK_CLIENT_CARD = "/client-card/:clientId",
    LINK_FINANCE_UPLOAD = "/finance-upload",
    LINK_ORDER_ACT_REGISTRY = "/order/act-registry",
    LINK_DISPUTES_POSTFIX = "/:status/page/:pageNumber/size/:pageSize/:disputeId?",
    LINK_DISPUTES = `/disputes/status${LINK_DISPUTES_POSTFIX}`,
    LINK_DISPUTES_CLIENT_ADMIN = `${LINK_CLIENT_CARD}/disputes/status${LINK_DISPUTES_POSTFIX}`,
    LINK_DISPUTES_CHAT_LIST = "/disputes/chats/:status/page/:pageNumber/size/:pageSize/:disputeId?/:orderId?",
    LINK_DISPUTES_CLIENT_ADMIN_CHAT_LIST = `/disputes/:clientId/chats${LINK_DISPUTES_POSTFIX}/:orderId?`,
    LINK_CHAT_LIST = "/chats/:clientId/page/:pageNumber/size/:pageSize/:orderId?/:chatId?/",
    LINK_ALL_ROLES_CHAT_LIST = "/chats/page/:pageNumber/size/:pageSize/:orderId?/:chatId?/",
    LINK_ORDER_LIST = "/order/list",
    LINK_ORDER_TEMPLATES_LIST = "/order/templates",
    LINK_ORDER_GROUPS_LIST = "/order/groups",
    LINK_ORDER_REGISTRY_INVITATIONS = "/order/registry-invitations",
    LINK_CLIENT_ORDER_LIST = `${LINK_CLIENT_CARD}${LINK_ORDER_LIST}`,
    LINK_CLIENT_ORDER_TEMPLATES_LIST = `${LINK_CLIENT_CARD}${LINK_ORDER_TEMPLATES_LIST}`,
    LINK_CLIENT_ORDER_GROUPS_LIST = `${LINK_CLIENT_CARD}${LINK_ORDER_GROUPS_LIST}`,
    LINK_CLIENT_ORDER_GROUPS_CARD = `${LINK_CLIENT_ORDER_GROUPS_LIST}/:orderGroupId`,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST = `${LINK_CLIENT_CARD}${LINK_ORDER_REGISTRY_INVITATIONS}`,
    LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_CARD = `${LINK_CLIENT_ORDER_REGISTRY_INVITATIONS_LIST}/:registryInvitationId`,
    LINK_CLIENT_ADD_ORDERS_GROUP_PAGE = `${LINK_CLIENT_ORDER_GROUPS_CARD}/add-orders`,
    LINK_CLIENT_ORDER_ACT_REGISTRY_LIST = `${LINK_CLIENT_CARD}${LINK_ORDER_ACT_REGISTRY}`,
    LINK_CLIENT_ORDER_ACT_REGISTRY_CARD = `${LINK_CLIENT_ORDER_ACT_REGISTRY_LIST}/:registryId`,
    LINK_CLIENT_ORDERS_ANALYTICS = `${LINK_CLIENT_CARD}/order-analytics`,
    LINK_CLIENT_ORDERS_APPLICATION_ANALYTICS = `${LINK_CLIENT_ORDERS_ANALYTICS}/application-analytics/:subpage`,
    LINK_CLIENT_OPERATOR_REPORTS = `${LINK_CLIENT_CARD}${LINK_OPERATOR_REPORTS}`,
    LINK_CONTRACTOR_NEW = "/contractor-list/new",

    LINK_CLIENT_MEMBERS = "/members-list",
    LINK_CLIENT_MEMBERS_LIST = `${LINK_CLIENT_CARD}${LINK_CLIENT_MEMBERS}`,
    LINK_CLIENT_MEMBERS_CARD = `${LINK_CLIENT_MEMBERS_LIST}/:clientContractorId`,

    LINK_CLIENT_INFO = `${LINK_CLIENT_CARD}/info`,
    LINK_SETTINGS = "/settings",
    LINK_CLIENT_GROUP = "/client-group",
    LINK_SETTINGS_INFO = "/settings/info",
    LINK_SETTINGS_JOINING_REQUESTS = `${LINK_SETTINGS}/joining-requests`,
    LINK_INSTRUCTION = "/instruction",
    LINK_INSTRUCTION_CLIENT_ADMIN = `${LINK_INSTRUCTION}/:clientId/naimix`,
    LINK_INSTRUCTION_KEDO_CLIENT_ADMIN = `${LINK_INSTRUCTION}/:clientId/kedo`,

    LINK_SETTINGS_INSTRUCTION = `${LINK_SETTINGS}${LINK_INSTRUCTION}/naimix`,
    LINK_SETTINGS_INSTRUCTION_KEDO = `${LINK_SETTINGS}${LINK_INSTRUCTION}/kedo`,
    LINK_SETTINGS_CLIENT_GROUP = `${LINK_SETTINGS}${LINK_CLIENT_GROUP}`,
    LINK_SETTINGS_CLIENT_GROUP_CARD = `${LINK_SETTINGS}${LINK_CLIENT_GROUP}/card/:groupId/:tab`,
    LINK_SETTINGS_TECHSUPPORT_LIST = `${LINK_SETTINGS}/techsupport-list`,
    LINK_SETTINGS_USERS_ROLES_LIST = `${LINK_SETTINGS}/users-roles`,
    LINK_SETTINGS_DIRECTORIES = `${LINK_SETTINGS}/directories`,
    LINK_SETTINGS_DIRECTORIES_KEDO = `${LINK_SETTINGS_DIRECTORIES}/kedo`, // Вкладка "КЭДО"
    // Настройки/Справочники/КЭДО/Типы загруженных документов
    LINK_SETTINGS_DIRECTORIES_KEDO_LOAD_DOCUMENT_TYPES = `${LINK_SETTINGS_DIRECTORIES_KEDO}${KEDO_LOAD_DOCUMENT_TYPES}`,
    // Настройки/Справочники/КЭДО/Типы шаблонных документов
    LINK_SETTINGS_DIRECTORIES_KEDO_TEMPLATE_DOCUMENT_TYPES = `${LINK_SETTINGS_DIRECTORIES_KEDO}${KEDO_TEMPLATE_DOCUMENT_TYPES}`,
    // Настройки/Справочники/КЭДО/Должности
    LINK_SETTINGS_DIRECTORIES_KEDO_POSITIONS = `${LINK_SETTINGS_DIRECTORIES_KEDO}${KEDO_POSITIONS}`,
    // Настройки/Справочники/КЭДО/Структурные подразделения
    LINK_SETTINGS_DIRECTORIES_KEDO_SUBDIVISIONS = `${LINK_SETTINGS_DIRECTORIES_KEDO}${KEDO_SUBDIVISIONS}`,
    LINK_SETTINGS_KEYWORDS = `${LINK_SETTINGS_DIRECTORIES}/keywords`,
    LINK_SETTINGS_ORDER_CATEGORIES = `${LINK_SETTINGS_DIRECTORIES}/order-categories`,
    LINK_SETTINGS_TECHSUPPORT_CARD = `${LINK_SETTINGS}/techsupport-card/:caseNumber`,
    LINK_SETTINGS_SPECIALTY_LIST = `${LINK_SETTINGS_DIRECTORIES}/specialty-list`,
    LINK_SETTINGS_DEPARTMENT_FEDERAL_MIGRATION_LIST = `${LINK_SETTINGS_DIRECTORIES}/federal-migration-list`,
    LINK_SETTINGS_MEMBERS_LIST = `${LINK_SETTINGS}/members-list/page/:pageNumber/size/:pageSize/:subpage`,
    LINK_SETTINGS_MEMBERS_CARD = `${LINK_SETTINGS}/member-card/:clientContractorId`,
    LINK_SETTINGS_LOG = `${LINK_SETTINGS}/log`,
    LINK_SETTINGS_FNS_SERVICE = `${LINK_SETTINGS}/fns-service`,
    LINK_SETTINGS_EVENT_LOG = `${LINK_SETTINGS}/log/event`,
    LINK_SETTINGS_SERVICE = `${LINK_SETTINGS}/service`, // Вкладка Настройки/Сервис
    LINK_SETTINGS_TKB_TRANSFERS = `${LINK_SETTINGS_SERVICE}/tkb-transfers`,
    LINK_SETTINGS_SERVICE_SETTINGS = `${LINK_SETTINGS_SERVICE}/settings`,
    LINK_SETTINGS_SERVICE_TASKS_STATS = `${LINK_SETTINGS_SERVICE}/task-stats`,

    LINK_SETTINGS_SIGNING_DOCUMENT_LOG = `${LINK_SETTINGS}/log/signing-documents`,
    LINK_SETTINGS_SPECIALTY_OFFER = `${LINK_SETTINGS_DIRECTORIES}/specialty-offer`,
    LINK_SETTINGS_DOCUMENT_TYPES = `${LINK_SETTINGS_DIRECTORIES}/document-types`,
    LINK_SETTINGS_STATEMENT_TYPES = `${LINK_SETTINGS_DIRECTORIES}/statement-types`,
    LINK_SETTINGS_STOP_WORDS = `${LINK_SETTINGS_DIRECTORIES}/stop-words`,
    LINK_SETTINGS_DIRECTORIES_TARRIF_GRID = `${LINK_SETTINGS_DIRECTORIES}/tarrif-grid`,
    LINK_SETTINGS_DIRECTORIES_CATEGORY_SPECIALITIES = `${LINK_SETTINGS_DIRECTORIES}/category-specialities`,
    LINK_SETTINGS_DIRECTORIES_BANKS = `${LINK_SETTINGS_DIRECTORIES}/banks`,
    LINK_SETTINGS_DIRECTORIES_BANKS_DETAILS = `${LINK_SETTINGS_DIRECTORIES_BANKS}/details`,
    LINK_SETTINGS_DIRECTORIES_BANKS_SBP = `${LINK_SETTINGS_DIRECTORIES_BANKS}/sbp`,
    LINK_SETTINGS_DIRECTORIES_BANKS_BALANCES = `${LINK_SETTINGS_DIRECTORIES_BANKS}/balances`,
    LINK_SETTINGS_DIRECTORIES_FLASH_CALL = `${LINK_SETTINGS_DIRECTORIES}/flash-call`,
    LINK_SETTINGS_DIRECTORIES_WITHDRAWAL_COMMISSION = `${LINK_SETTINGS_DIRECTORIES}/withdrawal-commission`,

    LINK_DOCUMENT_LIST = "/document-list",
    LINK_DEPOSIT_LIST = "/deposit-list",
    LINK_AGENCY_CONTRACT = "/agency-contract",
    LINK_STANDART_DOCUMENTS = "/standart-documents",
    LINK_INSURANCE_HISTORY = "/insurance-history",
    LINK_CLIENT_DEPOSIT_LIST = `${LINK_CLIENT_CARD}${LINK_DEPOSIT_LIST}/:subPage`,
    LINK_FINANCE_PAYMENT_LIST = "/finance/list/payment",
    LINK_FINANCE_NDFL_PAYMENT_LIST = "/finance/list/ndfl-payment",
    LINK_FINANCE_INDIVIDUAL_PAYMENT_LIST = "/finance/list/individual-payment",
    LINK_CLIENT_PAYMENTS_LIST = `${LINK_CLIENT_CARD}${LINK_FINANCE_PAYMENT_LIST}`,
    LINK_CLIENT_PAYMENTS_ORDERS_LIST = `${LINK_CLIENT_CARD}/finance/list/payment/orders`,
    LINK_CLIENT_NDFL_PAYMENTS_LIST = `${LINK_CLIENT_CARD}/civil-performer-payments`,
    LINK_CLIENT_INDIVIDUAL_PAYMENT_LIST = `${LINK_CLIENT_CARD}/individual-payment`,
    LINK_CLIENT_PAYMENTS_TASKS_LIST = `${LINK_CLIENT_CARD}/finance/list/payment/tasks/:paymentNumberFilter?`,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_ARCHIVED = `${LINK_CLIENT_CARD}/finance/:paymentNumberFilter?/registry/true`,
    LINK_CLIENT_CANCELLED_CHECKS = `${LINK_CLIENT_CARD}/finance/canceled-checks`,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST_NO_ARCHIVED = `${LINK_CLIENT_CARD}/finance/:paymentNumberFilter?/registry/false`,
    LINK_CLIENT_REGISTRY_PAYMENTS_LIST = `${LINK_CLIENT_CARD}/finance/:paymentNumberFilter?/registry/:archived`,
    LINK_CLIENT_REGISTRY_PAYMENTS_CARD = `${LINK_CLIENT_REGISTRY_PAYMENTS_LIST}/card/:registryId`,
    LINK_CLIENT_DOCUMENT_ROUTE = `${LINK_CLIENT_CARD}/document`,
    LINK_CLIENT_DOCUMENT_LIST = `${LINK_CLIENT_DOCUMENT_ROUTE}/list`,
    LINK_CLIENT_REGISTRY_FC_LIST = `${LINK_CLIENT_DOCUMENT_ROUTE}/fc-registry-list`,
    LINK_CLIENT_REGISTRY_FC_CARD = `${LINK_CLIENT_REGISTRY_FC_LIST}/card/:registryId`,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST = `${LINK_CLIENT_DOCUMENT_ROUTE}/additional-agreements`,
    LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_CARD = `${LINK_CLIENT_REGISTRY_OF_ADDITIONAL_AGREEMENT_LIST}/card/:id`,
    LINK_CLIENT_REGISTRY_FC_ADD_ITEM_FORM = `${LINK_CLIENT_REGISTRY_FC_CARD}/add`,
    LINK_CLIENT_EXPORT_DOCUMENT_LIST = `${LINK_CLIENT_DOCUMENT_ROUTE}/export`,
    LINK_CLIENT_NOTIFICATIONS_DOCUMENT_LIST = `${LINK_CLIENT_DOCUMENT_ROUTE}/notifications`,
    LINK_CLIENT_AGENCY_CONTRACT = `${LINK_CLIENT_CARD}${LINK_AGENCY_CONTRACT}`,
    LINK_CLIENT_STANDART_DOCUMENTS = `${LINK_CLIENT_CARD}${LINK_STANDART_DOCUMENTS}`,
    //advertisement
    LINK_CLIENT_ADVERTISEMENT = `${LINK_CLIENT_CARD}/advertisement`,
    LINK_CLIENT_CARD_ADVERTISEMENT = `${LINK_CLIENT_CARD}/advertisement/:advertisementId/:subPage`,
    // Компания/Объявления/Исполнители на заказах
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS = `${LINK_CLIENT_ADVERTISEMENT}/:advertisementId/funnels`,
    // Компания/Объявления/Исполнители на заказах/Вкладки внутри - Отклики, Приглашенные, Предварительно одобренные,
    // Подписывают договор, Нанятые, Отказы
    LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS_FUNNEL = `${LINK_CLIENT_CARD_ADVERTISEMENT_CONTRACTORS_ON_ORDERS}/:subpage`,
    LINK_ORDER_CARD = `${LINK_CLIENT_CARD}${LINK_ORDER_LIST}/:orderId/:page/:subpage`,
    LINK_ARCHIVE_LIST = "/client_archive_list",
    //Рекрутмент
    LINK_CLIENT_RECRUITMENT = `${LINK_CLIENT_CARD}/recruitment`,
    LINK_CLIENT_RECRUITMENT_VACANCIES = `${LINK_CLIENT_RECRUITMENT}/vacancies`, // Рекрутмент/Вакансии
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD = `${LINK_CLIENT_RECRUITMENT_VACANCIES}/:vacancyId`, // Рекрутмент/Вакансии/Карточка
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD}/description`, // Рекрутмент/Вакансии/Описание
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL = `${LINK_CLIENT_RECRUITMENT}/access-control`, // Рекрутмент/Управление доступом
    // Рекрутмент/Вакансии/Описание/Все отклики
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION}/responses`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES}/:id`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD_INFO = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_RESPONSES_CARD}/info`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD}/description/new-responses`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES}/:id`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD_INFO = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_NEW_RESPONSES_CARD}/info`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_ALL_CANDIDATES = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION}/candidates`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION}/:activeFunnelStatusFilter/info`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL}/:id`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_INFO = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD}/info`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_FEED = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD}/feed`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD_RESPONSES = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_DESCRIPTION_FUNNEL_CARD}/responses`,
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_CANDIDATES = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD}/candidates`, // Рекрутмент/Вакансии/Карточка/Кандидаты
    LINK_CLIENT_RECRUITMENT_VACANCIES_CARD_JOB_BOARDS = `${LINK_CLIENT_RECRUITMENT_VACANCIES_CARD}/job-boards`, // Рекрутмент/Вакансии/Карточка/Джоб-борды
    LINK_CLIENT_RECRUITMENT_CANDIDATES = `${LINK_CLIENT_RECRUITMENT}/candidates`, // Рекрутмент/Кандитаты по вакансиям
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD = `${LINK_CLIENT_RECRUITMENT}/candidates/:id`, // Рекрутмент/Кандитаты по вакансиям/Карточка
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_INFO = `${LINK_CLIENT_RECRUITMENT}/candidates/:id/info`,
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_FEED = `${LINK_CLIENT_RECRUITMENT}/candidates/:id/feed`,
    LINK_CLIENT_RECRUITMENT_CANDIDATES_CARD_RESPONSES = `${LINK_CLIENT_RECRUITMENT}/candidates/:id/responses`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES = `${LINK_CLIENT_RECRUITMENT}/directories`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_FUNNELS = `${LINK_CLIENT_RECRUITMENT_DIRECTORIES}/funnels`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_STATUSES = `${LINK_CLIENT_RECRUITMENT_DIRECTORIES}/statuses`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_JOB_REQUIREMENTS = `${LINK_CLIENT_RECRUITMENT_DIRECTORIES}/job-requirements`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_OBSERVERS = `${LINK_CLIENT_RECRUITMENT_DIRECTORIES}/observers`,
    LINK_CLIENT_RECRUITMENT_DIRECTORIES_REJECTION_REASONS = `${LINK_CLIENT_RECRUITMENT_DIRECTORIES}/rejection-reasons`,
    // Рекрутмент/Управление доступом
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS = `${LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL}/recruiters-groups`,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS_CARD = `${LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_RECRUITERS_GROUPS}/:id`,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_JOB_BOARDS = `${LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL}/job-boards`,
    LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL_OBSERVERS = `${LINK_CLIENT_RECRUITMENT_ACCESS_CONTROL}/observers`,
    //Задания
    LINK_CLIENT_CROWD = `${LINK_CLIENT_CARD}/crowd`,
    LINK_CLIENT_CROWD_TASK_REGISTRY = `${LINK_CLIENT_CROWD}/task-registry`, // Задания, Реестр заданий
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD = `${LINK_CLIENT_CROWD}/task-registry/:taskId/:subpage`, // Задания, Карточка задания
    LINK_CLIENT_CROWD_TASK_REGISTRY_CARD_INVITE_CANDIDATES = `${LINK_CLIENT_CROWD}/task-registry/:taskId/invite-candidates`, // Задания, Карточка задания, поиск исполнителей
    LINK_CLIENT_CROWD_TASK_GROUPS = `${LINK_CLIENT_CROWD}/task-groups`, // Задания, Группы заданий
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD = `${LINK_CLIENT_CROWD}/task-groups/:taskGroupId`, // Задания, Карточка группы заданий
    LINK_SEARCH_PAGE = "/search-page",
    LINK_CLIENT_CROWD_TASK_GROUPS_CARD_SEARCH_PAGE = `${LINK_CLIENT_CROWD}/task-groups/:taskGroupId${LINK_SEARCH_PAGE}`, // Задания, Карточка группы заданий, Поиск заданий
    LINK_CLIENT_CROWD_TASK_CHATS = `${LINK_CLIENT_CROWD}/task-chats/:contractorId?`, // Задания, Чаты заданий
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY = `${LINK_CLIENT_CROWD}/task-act-registry`, // Задания, Реестр актов по заданиям
    LINK_CLIENT_CROWD_TASK_ACT_REGISTRY_CARD = `${LINK_CLIENT_CROWD_TASK_ACT_REGISTRY}/:registryId`, // Задания, Реестр актов по заданиям
    LINK_CLIENT_CROWD_TASK_ANALYTICS = `${LINK_CLIENT_CROWD}/task-analytics/:subpage`; // Задания, Аналитика по заданиям

const PAGINATION = "page/:pageNumber/size/:pageSize";

export const
    LINK_FINANCE = `/finance/list/:paid/${PAGINATION}`,
    LINK_FINANCE_CARD = "/finance/list/:paid/:clientId/:orderId",
    LINK_FINANCE_LOG = "/finance/log/",
    LINK_FINANCE_PIVOT = "/finance/pivot/",
    LINK_FINANCE_BANK = "/finance/bank",
    LINK_FINANCE_PIVOT_TABLE = "/finance/pivot/page/:pageNumber/size/:pageSize/:clientId?/from/:from?/to/:to?",
    LINK_PATENT_PIVOT_TABLE = "/finances/patents/pivot",
    LINK_CIVIL_PIVOT_TABLE = "/finances/civil/pivot",
    LINK_FINANCE_EXPORT = "/finances/export",
    LINK_FINANCE_EXPORT_FULL = `${LINK_FINANCE_EXPORT}/page/:pageNumber/size/:pageSize/:clientId?/`,
    LINK_FINANCE_EXPORT_DOCUMENTS = `${LINK_FINANCE_EXPORT}/admins-documents`,
    LINK_CLIENT_FINANCE_EXPORT_FULL = `${LINK_CLIENT_CARD}${LINK_FINANCE_EXPORT}/page/:pageNumber/size/:pageSize/`,
    LINK_CLIENT_FINANCE = `${LINK_CLIENT_CARD}/finance/list/:paid/${PAGINATION}`,
    LINK_CLIENT_FINANCE_UPLOAD = `${LINK_CLIENT_CARD}${LINK_FINANCE_UPLOAD}`,
    LINK_CLIENT_INSURANCE_HISTORY = `${LINK_CLIENT_CARD}${LINK_INSURANCE_HISTORY}`,
    LINK_CLIENT_FINANCE_CARD = `${LINK_CLIENT_CARD}/finance/list/:paid/:orderId`,
    LINK_FINANCE_ACTIVE = "/finance/",
    LINK_FINANCE_OPERATOR_REPORTS = "/finance/operator-reports",
    LINK_FINANCE_SYSTEM_TRANSACTIONS = "/finance/system-transactions/:transactionNumFilter?",
    LINK_FINANCE_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS = "/finance/reconciliation-acts",
    LINK_CLIENT_MUTUAL_SETTLEMENTS_RECONCILIATION_ACTS = `${LINK_CLIENT_CARD}/reconciliation-acts`,
    LINK_FINANCE_CANCELED_CHECKS = "/finance/canceled-checks",
    LINK_FINANCE_ORDERS = "/finance/orders",
    LINK_FINANCE_PATENT_PAYMENTS = "/finance/patent-payments",
    LINK_FINANCE_CROWD_TASKS = "/finance/crowd/tasks",
    LINK_FINANCE_REGISTRY_PAYMENTS = "/finance/registry-payments/:archived",
    LINK_FINANCE_PATENT_PAYMENT_LIST = "/finance/patent-payments/:paymentNumberFilter?",
    LINK_FINANCE_CROWD_PAYMENT_LIST = "/finance/crowd-payments/:paymentNumberFilter?";

export const LINK_MAILINGS_LIST = "/mailings/list";
export const LINK_CLIENT_MAILINGS_LIST = `${LINK_CLIENT_CARD}${LINK_MAILINGS_LIST}`;
export const LINK_MARKETING_BUILDER = "/marketing/link-builder";
export const LINK_PROJECTS = "/projects";
export const LINK_OBJECTS = "/objects";
export const LINK_CONTRACTORS = "/contractors";
export const LINK_CONTRACTORS_RESOURCES = `${LINK_CONTRACTORS}/resources`;
export const LINK_CLIENT_REMOTE_CONTRACTORS = `${LINK_CONTRACTORS}/client-remote`;
export const LINK_CONTRACTOR_ONBOARDING_LEADS = `${LINK_CONTRACTORS}/onboarding-leads`;
export const LINK_CLIENT_ON_CHECK_CONTRACTORS = `${LINK_CONTRACTORS}/on-check`;
export const LINK_CLIENT_ADDITIONAL_DOCUMENTS_CONTRACTORS = `${LINK_CONTRACTORS}/additional-documents`;
export const LINK_ADDING_CLIENT_REMOTE_CONTRACTORS = `${LINK_CONTRACTORS}/client-remote/:groupId/add`;
export const LINK_PROJECTS_LIST = `${LINK_PROJECTS}/list`;
export const LINK_CLIENT_PROJECTS_LIST = `${LINK_CLIENT_CARD}${LINK_PROJECTS_LIST}`;
export const LINK_CLIENT_OBJECTS_LIST = `${LINK_CLIENT_CARD}${LINK_OBJECTS}`;
export const LINK_CLIENT_PROJECTS_CARD = `${LINK_CLIENT_CARD}${LINK_PROJECTS}/card/:projectId`;
export const LINK_CLIENT_CONTRACTORS_LIST = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/type/:type`;
export const LINK_CLIENT_CONTRACTORS_RESOURCES = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/resources/:type`;
export const LINK_CLIENT_CARD_CONTRACTORS_RESOURCES = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/resources`;
export const LINK_CLIENT_CARD_CONTRACTORS_ON_CHECK = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/on-check`;
export const LINK_CLIENT_CARD_CONTRACTORS_ONBOARDING_SCENARIO_CARD = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/onboarding/:scenarioId`;
export const LINK_CLIENT_INVITING_CONTRACTORS_LIST = `${LINK_CLIENT_CARD}${LINK_CONTRACTORS}/order/:orderId/type/:type`;
export const LINK_CLIENT_PROJECTS_CARD_ORDER_LIST = `${LINK_CLIENT_PROJECTS_CARD}/orders`;
export const LINK_CLIENT_PROJECTS_CARD_TASK_LIST = `${LINK_CLIENT_PROJECTS_CARD}/tasks`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST = `${LINK_CLIENT_PROJECTS_CARD}/objects`;
export const LINK_CLIENT_PROJECTS_CARD_TEAM_LIST = `${LINK_CLIENT_PROJECTS_CARD}/teams`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD = `${LINK_CLIENT_PROJECTS_CARD_OBJECT_LIST}/:objectId`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TEAM_LIST = `${LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD}/teams`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_ORDERS_LIST = `${LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD}/orders`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_TASKS_LIST = `${LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD}/tasks`;
export const LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD_CONTRACTORS_LIST = `${LINK_CLIENT_PROJECTS_CARD_OBJECT_CARD}/contractors`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT = `${LINK_CLIENT_CARD}/document-management/:tab/:type?`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_DOCUMENT_LIST = `${LINK_CLIENT_CARD}/document-management/document-list`;
export const LINK_CLIENT_KEDO = `${LINK_CLIENT_CARD}/kedo`;
export const LINK_CLIENT_KEDO_MEMBERS = `${LINK_CLIENT_KEDO}/staff-registry`;
export const LINK_CLIENT_KEDO_PROFILE = `${LINK_CLIENT_KEDO_MEMBERS}/:kedoStaffId`;
export const LINK_CLIENT_KEDO_SIGNATURE_TRANSACTIONS = `${LINK_CLIENT_KEDO_PROFILE}/signature-transactions`;
export const LINK_CLIENT_KEDO_DOCUMENTS = `${LINK_CLIENT_KEDO}/documents`;
export const LINK_CLIENT_KEDO_DIRECTORIES = `${LINK_CLIENT_KEDO}/directories`;
export const LINK_CLIENT_KEDO_EXPORTS = `${LINK_CLIENT_KEDO}/exports`;
export const LINK_CLIENT_KEDO_LOGS = `${LINK_CLIENT_KEDO}/logs`;
export const LINK_CLIENT_KEDO_DIRECTORIES_LOAD_DOCUMENT_TYPES = `${LINK_CLIENT_KEDO_DIRECTORIES}${KEDO_LOAD_DOCUMENT_TYPES}`;
export const LINK_CLIENT_KEDO_DIRECTORIES_POSITIONS = `${LINK_CLIENT_KEDO_DIRECTORIES}${KEDO_POSITIONS}`;
export const LINK_CLIENT_KEDO_DIRECTORIES_SUBDIVISIONS = `${LINK_CLIENT_KEDO_DIRECTORIES}${KEDO_SUBDIVISIONS}`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_EXPORT = `${LINK_CLIENT_CARD}/document-management/export`;
export const LINK_CLIENT_PATENTS = `${LINK_CLIENT_CARD}/patents/:tab`;
export const LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST = `${LINK_CLIENT_PATENTS}`.replace(":tab", "patents-payment-list");
export const LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_CARD = `${LINK_CLIENT_PAYMENT_PATENTS_PAYMENT_LIST}/card/:patentRegistryId`;
export const LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST = `${LINK_CLIENT_PATENTS}/:subPage`.replace(":tab", "patents-deposits-list");
export const LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST_PAYMENTS = `${LINK_CLIENT_DEPOSIT_PATENTS_DEPOSITS_LIST}/:subTab`;
export const LINK_CLIENT_DOCUMENTS_PATENTS_DOCUMENTS_LIST = `${LINK_CLIENT_PATENTS}`.replace(":tab", "patents-documents-list");
export const LINK_CLIENT_EXPORT_PATENTS_EXPORTS_LIST = `${LINK_CLIENT_PATENTS}`.replace(":tab", "patents-exports-list");
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL = `${LINK_CLIENT_CARD}/document-management/personnel`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_STATEMENTS = `${LINK_CLIENT_CARD}/document-management/statements`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_DICTIONARIES = `${LINK_CLIENT_CARD}/document-management/dictionaries/:type?`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_POSITION_DICT = `${LINK_CLIENT_CARD}/document-management/dictionaries/positions`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_STRUCTURAL_UNITS_DICT = `${LINK_CLIENT_CARD}/document-management/dictionaries/structural-units`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD = `${LINK_CLIENT_CARD}/edm-staff-card/:staffId/:type`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_DOCS = `${LINK_CLIENT_CARD}/edm-staff-card/:staffId/doc-list`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_APPS = `${LINK_CLIENT_CARD}/edm-staff-card/:staffId/app-list`;
export const LINK_CLIENT_DOCUMENT_MANAGEMENT_PERSONNEL_CARD_PERS_DATA = `${LINK_CLIENT_CARD}/edm-staff-card/:staffId/personal-data`;

/* LANDING LINKS */
export const LANDING_SELF_CONTRACTOR = "/contractor",
    LANDING_FAQ = "/faq",
    LANDING_ABOUT = "/about",
    LANDING_CONTACTS = "/contacts";

export const LINK_PROMOCODE_CHANNEL_LIST = "/promocode-channel-list",
    LINK_PROMOCODE_CHANNEL_CARD = "/promocode-channel-card/:promotionId";

export const LINK_NOTIFICATIONS_LIST = "/notifications-list";
export const LINK_CLIENT_NOTIFICATIONS_LIST = `${LINK_CLIENT_CARD}${LINK_NOTIFICATIONS_LIST}`;

export const LINK_SETTING_NOTIFICATIONS_LIST = "/setting-notifications";
export const LINK_CLIENT_SETTING_NOTIFICATIONS_LIST = `${LINK_CLIENT_CARD}${LINK_SETTING_NOTIFICATIONS_LIST}`;

export const LINK_CRM = "/crm";
export const LINK_CRM_TICKET = `${LINK_CRM}/ticket`;
export const LINK_CRM_TICKET_LIST = `${LINK_CRM_TICKET}/list/:subpage`;
export const LINK_CRM_TICKET_LIST_IN_WORK = `${LINK_CRM_TICKET}/list/in-work`;
export const LINK_CRM_TICKET_CARD_BY_ID = `${LINK_CRM_TICKET_LIST}/card/:ticketId/`;
export const LINK_CRM_TICKET_CARD = `${LINK_CRM_TICKET_CARD_BY_ID}:_subpage`;
export const LINK_CLIENT_CARD_CRM_TICKET_LIST = `${LINK_CLIENT_CARD}${LINK_CRM_TICKET_LIST}`;
export const LINK_CLIENT_CARD_CRM_TICKET_LIST_IN_WORK = `${LINK_CLIENT_CARD}${LINK_CRM_TICKET_LIST_IN_WORK}`;
export const LINK_CLIENT_CARD_CRM_TICKET_CARD_BY_ID = `${LINK_CLIENT_CARD}${LINK_CRM_TICKET_CARD_BY_ID}`;
export const LINK_CLIENT_CARD_CRM_TICKET_CARD = `${LINK_CLIENT_CARD}${LINK_CRM_TICKET_CARD}`;
export const LINK_CONTRACTOR_CARD_CRM_TICKET_LIST = `${LINK_CONTRACTOR_CARD}${LINK_CRM_TICKET_LIST}`;
export const LINK_CONTRACTOR_CRM_TICKET_LIST_IN_WORK = `${LINK_CONTRACTOR_CARD}${LINK_CRM_TICKET_LIST_IN_WORK}`;
export const LINK_CONTRACTOR_CRM_TICKET_CARD_BY_ID = `${LINK_CONTRACTOR_CARD}${LINK_CRM_TICKET_CARD_BY_ID}`;
export const LINK_CONTRACTOR_CRM_TICKET_CARD = `${LINK_CONTRACTOR_CARD}${LINK_CRM_TICKET_CARD}`;
export const LINK_CONTRACTOR_CARD_EVENT_LOG = `${LINK_CONTRACTOR_CARD}/event-log`;
export const LINK_CONTRACTOR_CARD_EVENT_LOG_LIST = `${LINK_CONTRACTOR_CARD}/event-log/list`;
export const LINK_CONTRACTOR_CARD_EVENT_LOG_DOCUMENT_SIGNING_LOG = `${LINK_CONTRACTOR_CARD}/event-log/document-signing-log`;
export const LINK_CONTRACTOR_CARD_EVENT_LOG_ORDER_HISTORY = `${LINK_CONTRACTOR_CARD}/event-log/order-history`;
export const LINK_CONTRACTOR_CARD_EVENT_LOG_NOTIFICATION_LOG = `${LINK_CONTRACTOR_CARD}/event-log/notification-log`;

export const LINK_STOP_LIST = "/stop-list";
export const LINK_CLIENT_STOP_LIST = `${LINK_CLIENT_CARD}${LINK_STOP_LIST}`;

export const LINK_BRIGADE = "/brigade";
export const LINK_CLIENT_BRIGADE = `${LINK_CLIENT_CARD}${LINK_BRIGADE}/:page`;
export const LINK_FEEDBACKS = "/feedback-list";
export const LINK_CLIENT_FEEDBACKS = `${LINK_CLIENT_CARD}${LINK_FEEDBACKS}`;
export const LINK_CLIENT_BRIGADE_ORDER_OBJECT_LIST = `${LINK_CLIENT_CARD}${LINK_BRIGADE}/${BRIGADE_ORDER_OBJECT_OBJECT}`;
export const LINK_CLIENT_BRIGADE_TIMESHEET = `${LINK_CLIENT_CARD}${LINK_BRIGADE}/${BRIGADE_TIME_SHEET}`;
export const LINK_CLIENT_BRIGADE_PAYMENTS = `${LINK_CLIENT_CARD}${LINK_BRIGADE}/${BRIGADE_PAYMENTS}`;
export const LINK_CLIENT_BRIGADE_LIST = `${LINK_CLIENT_CARD}${LINK_BRIGADE}/${BRIGADE_LIST}`;
export const LINK_CLIENT_BRANDS = `${LINK_CLIENT_CARD}/brands`;
export const LINK_TEMPLATES = "/templates";
// АЧ / Шаблоны документов
export const LINK_TEMPLATES_LIST = `${LINK_TEMPLATES}/:subpage`;
export const LINK_TEMPLATES_LIST_WITH_TAB = `${LINK_TEMPLATES}/:subpage/:subTab`;
export const LINK_TEMPLATES_CONTRACTS_LIST = `${LINK_TEMPLATES}/contracts`;
export const LINK_TEMPLATES_KEDO = `${LINK_TEMPLATES}/kedo`;
export const LINK_TEMPLATES_KEDO_LIST = `${LINK_TEMPLATES_KEDO}/list`;
export const LINK_TEMPLATES_KEDO_LOG = `${LINK_TEMPLATES_KEDO}/logs`;
export const LINK_CLIENT_CARD_TEMPLATES = `${LINK_CLIENT_CARD}${LINK_TEMPLATES}`;
export const LINK_CLIENT_CARD_TEMPLATES_LIST = `${LINK_CLIENT_CARD}${LINK_TEMPLATES_LIST}`;
export const LINK_CLIENT_CARD_TEMPLATES_LIST_WITH_TAB = `${LINK_CLIENT_CARD}${LINK_TEMPLATES_LIST_WITH_TAB}`;
// КЧ / Шаблоны документов / Договоры
export const LINK_CLIENT_CARD_TEMPLATES_CONTRACTS_LIST = `${LINK_CLIENT_CARD}${LINK_TEMPLATES_CONTRACTS_LIST}`;
// КЧ / Шаблоны документов / Заявки по заказам
export const LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_ORDERS = `${LINK_CLIENT_CARD}/templates/applications/orders`;
// КЧ / Шаблоны документов / Заявки по заданиям
export const LINK_CLIENT_CARD_TEMPLATES_APPLICATIONS_TASKS = `${LINK_CLIENT_CARD}/templates/applications/tasks`;
// КЧ / Шаблоны документов / Акты по заказам
export const LINK_CLIENT_CARD_TEMPLATES_ACTS_ORDERS = `${LINK_CLIENT_CARD}/templates/acts/orders`;
// КЧ / Шаблоны документов / Акты по заданиям
export const LINK_CLIENT_CARD_TEMPLATES_ACTS_TASKS = `${LINK_CLIENT_CARD}/templates/acts/tasks`;
// КЧ / Шаблоны документов / Иные документы
export const LINK_CLIENT_CARD_TEMPLATES_OTHER_DOCUMENTS = `${LINK_CLIENT_CARD}/templates/other-documents`;

export const LINK_REVIEWS_LIST = "/feedbacks-list/:subpage";

export const LINK_FULL_INFO_MAP = "/info-map";

export const SUBPAGE_OBJECTS_CONTRACTORS = "contractors";
export const SUBPAGE_OBJECTS_ORDERS_AND_ADVERTISEMENT = "orders-and-advertisements";
export const LINK_MAP_OBJECTS_LIST = `${LINK_FULL_INFO_MAP}/objects/:subpage`;

export const OPEN_NAIMIX_CATALOG_ORDER = ":domain/catalog/order/:clientId/:orderId";

export const LINK_AUTH_REDIRECT = "/auth-redirect";
export const LINK_BOARD_CONNECT = "/board-connect";
export const LINK_CMS_STRAPI_PROD = "https://cms.naimix.info";

export class LinkNavigator {
    static admin = {
        contractor: {
            list: "/contractor-list",
            card: "/contractor-card/:contractorId",
        },
        civilDeposit: // Депозит НДФЛ
            "/civil-deposit-list",
        deposit:
            "/deposit-list",
        finance: {
            payments:
                "/finance/list/payment/:paymentNumberFilter?",
            paymentsActivePath:
                "/finance/list/payment",
            civilPayments:
                "/finance/civil-payments/:paymentNumberFilter?",
            patentPayments:
                "/finance/patent-payments",
            orders:
                "/finance/orders",
            summary:
                "/finance/pivot/",
            patentsSummary:
                "/finances/patents/pivot",
            bank:
                "/finance/bank",
            operatorReports:
                "/finance/operator-reports",
            systemTransactions:
                "/finance/system-transactions",
            canceledChecks: // Аннулированные чеки ФНС
                "/finance/canceled-checks",
            registryPayments: // Реестровые выплаты
                "/finance/registry-payments/:archived",
        },
        settings: { // Настройки
            documentsEdit: // Исправление документов
                "/settings/documents-correction",
            ml: // Сервис
                "/settings/ml",
            directories: { // Справочники
                tariffGrid: // Тарифная сетка
                    "/settings/directories/tarrif-grid",
                categorySpecialities: // Категории видов деятельности
                    "/settings/directories/category-specialities",
                banks: // Банки
                    "/settings/directories/banks",
            },
            collectionBankDetails: // Сбор банковских реквизитов
                "/settings/collection-bank-details",
        },
    };

    static client = {
        orders: {
            actRegistry: "/client-card/:clientId/order/act-registry",
        },
        finance: {
            civilPerformerPayments: "/client-card/:clientId/civil-performer-payments/:paymentNumberFilter?",
            deposit: "/client-card/:clientId/deposit-list/:subPage",
            depositRegistryOrders: "/client-card/:clientId/deposit-list/:subPage/:subTab",
            civilDeposit: "/client-card/:clientId/civil-deposit-list/:subPage",
            civilDepositRegistryOrders: "/client-card/:clientId/civil-deposit-list/:subPage/:subTab",
        },
        documents: {},
        edo: {
            documentsRegistries: `${LINK_CLIENT_CARD}/document-management/documents-registries`,
            documentsRegistriesCard: `${LINK_CLIENT_CARD}/document-management/documents-registries/:registryId`,
            documentsRegistriesCardAddItem: `${LINK_CLIENT_CARD}/document-management/documents-registries/:registryId/add`,
        },
        reports: {},
        company: {},
        disputes: {},
        notifications: {},
        instructions: "",
        chats: {
            list: "/client-card/:clientId/chat-list/:orderId?/:chatId?",
        },
    };
}