import {all, put, takeEvery} from "redux-saga/effects";
import {createSelector} from "reselect";

import {getTotalPages} from "../utils/mathHelper";
import request from "../utils/postman";
import {toastError, toastSuccess} from "../utils/toastHelper";

import {CLIENT} from "../constants/messages";

const controller = "clientsApplication";


const CLIENTS_APPLICATION_GET_PAGE_REQUEST = "CLIENTS_APPLICATION_GET_PAGE_REQUEST";
const CLIENTS_APPLICATION_GET_PAGE_SUCCESS = "CLIENTS_APPLICATION_GET_PAGE_SUCCESS";
const CLIENTS_APPLICATION_GET_PAGE_ERROR = "CLIENTS_APPLICATION_GET_PAGE_ERROR";

const CONFIRM_CLIENT_APPLICATION_REQUEST = "CONFIRM_CLIENT_APPLICATION_REQUEST";
const CONFIRM_CLIENT_APPLICATION_SUCCESS = "CONFIRM_CLIENT_APPLICATION_SUCCESS";
const CONFIRM_CLIENT_APPLICATION_ERROR = "CONFIRM_CLIENT_APPLICATION_ERROR";

const DECLINE_CLIENT_APPLICATION_REQUEST = "DECLINE_CLIENT_APPLICATION_REQUEST";
const DECLINE_CLIENT_APPLICATION_SUCCESS = "DECLINE_CLIENT_APPLICATION_SUCCESS";
const DECLINE_CLIENT_APPLICATION_ERROR = "DECLINE_CLIENT_APPLICATION_ERROR";

const GET_CLIENT_APPLICATION_STATUSES_BY_IDS_REQUEST = "GET_CLIENT_APPLICATION_STATUSES_BY_IDS_REQUEST";
const GET_CLIENT_APPLICATION_STATUSES_BY_IDS_SUCCESS = "GET_CLIENT_APPLICATION_STATUSES_BY_IDS_SUCCESS";
const GET_CLIENT_APPLICATION_STATUSES_BY_IDS_ERROR = "GET_CLIENT_APPLICATION_STATUSES_BY_IDS_ERROR";

const GET_BITRIX_LEAD_BY_ID_REQUEST = "GET_BITRIX_LEAD_BY_ID_REQUEST";
const GET_BITRIX_LEAD_BY_ID_SUCCESS = "GET_BITRIX_LEAD_BY_ID_SUCCESS";
const GET_BITRIX_LEAD_BY_ID_ERROR = "GET_BITRIX_LEAD_BY_ID_ERROR";

const CLIENT_APPLICATION_UPDATE_STORE = "CLIENT_APPLICATION_UPDATE_STORE";

const initial = {
    progress: null,
    errorMessage: "",
    progressGetPage: false,
    pageData: {},
    totalCount: null,
    list: [],
    clientApplicationStatuses: [],
    bitrixLeads: [],
};

export default (state = initial, {type, payload}) => {
    switch (type) {
    case GET_CLIENT_APPLICATION_STATUSES_BY_IDS_SUCCESS:
        return {
            ...state,
            clientApplicationStatuses: payload,
        };
    case CLIENTS_APPLICATION_GET_PAGE_REQUEST: {
        return {
            ...state,
            pageData: payload,
            progressGetPage: true,
        };
    }
    case CLIENTS_APPLICATION_GET_PAGE_SUCCESS: {
        const {applications, totalCount} = payload;

        return {
            ...state,
            list: applications,
            totalCount,
            progressGetPage: false,
        };
    }
    case CLIENTS_APPLICATION_GET_PAGE_ERROR: {
        return {
            ...state,
            progressGetPage: false,
            errorMessage: payload,
        };
    }
    case GET_BITRIX_LEAD_BY_ID_SUCCESS: {
        return {
            ...state,
            bitrixLeads: [
                ...state.bitrixLeads,
                payload.result,
            ],
        };
    }
    case CONFIRM_CLIENT_APPLICATION_REQUEST: {
        return {
            ...state,
            progress: true,
        };
    }
    case CONFIRM_CLIENT_APPLICATION_SUCCESS:
    case CONFIRM_CLIENT_APPLICATION_ERROR: {
        return {
            ...state,
            progress: false,
        };
    }
    case CLIENT_APPLICATION_UPDATE_STORE: {
        return {
            ...state,
            ...payload,
        };
    }
    default:
        return state;
    }
};

export function getClientApplicationStatusesByIds(payload) {
    return {
        type: GET_CLIENT_APPLICATION_STATUSES_BY_IDS_REQUEST,
        payload,
    };
}

export function clientsApplicationGetPage(payload) {
    return {
        type: CLIENTS_APPLICATION_GET_PAGE_REQUEST,
        payload,
    };
}

export function confirmClientApplication(payload) {
    return {
        type: CONFIRM_CLIENT_APPLICATION_REQUEST,
        payload,
    };
}

export function declineClientApplication(payload) {
    return {
        type: DECLINE_CLIENT_APPLICATION_REQUEST,
        payload,
    };
}

export function clientApplicationUpdateStore(payload) {
    return {
        type: CLIENT_APPLICATION_UPDATE_STORE,
        payload,
    };
}

export function getBitrixLeadById(payload) {
    return {
        type: GET_BITRIX_LEAD_BY_ID_REQUEST,
        payload,
    };
}

export const clientsApplicationSelector = state => state.clientsApplication;
export const clientsApplicationProgressSelector = createSelector(clientsApplicationSelector, ({progress}) => progress);
export const clientsApplicationTotalPagesSelector = createSelector(clientsApplicationSelector, ({totalCount, pageData}) => getTotalPages(totalCount, pageData.pageSize));
export const clientsApplicationTotalCountSelector = createSelector(clientsApplicationSelector, ({totalCount}) => totalCount);
export const clientsApplicationPageSelector = createSelector(clientsApplicationSelector, ({list, clientApplicationStatuses}) => {
    return list.map((item) => {
        const {applicationId} = item;

        const applicationStatusInfo = clientApplicationStatuses.find(item => (item.applicationId === applicationId)) || {};

        return {
            ...item,
            ...applicationStatusInfo,
        };
    });
});
export const clientsApplicationPageProgressSelector = createSelector(clientsApplicationSelector, ({progressGetPage}) => progressGetPage);
export const bitrixLeadsSelector = createSelector(clientsApplicationSelector, ({bitrixLeads}) => bitrixLeads);

export const clientsApplicationGetPageSaga = function* ({payload}) {
    try {
        const result = yield request.post(`${controller}/getPage`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: CLIENTS_APPLICATION_GET_PAGE_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: CLIENTS_APPLICATION_GET_PAGE_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CLIENTS_APPLICATION_GET_PAGE_ERROR,
            payload: error,
        });
    }
};

export const confirmClientApplicationSaga = function* ({payload}) {
    try {
        const {
            data,
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/confirmationApplication`, data);
        const {
            guid,
            errorMessage,
        } = result;

        if (!guid) {
            toastError(errorMessage ? errorMessage : CLIENT.CLIENT_ADD_ERROR_TEXT);

            yield put({
                type: CONFIRM_CLIENT_APPLICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        toastSuccess("Заявка подтверждена");
        onSuccess(guid);

        yield put({
            type: CONFIRM_CLIENT_APPLICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CONFIRM_CLIENT_APPLICATION_ERROR,
            payload: error,
        });
    }
};

export const declineClientApplicationSaga = function* ({payload}) {
    try {
        const {
            onSuccess = () => {},
        } = payload;

        const result = yield request.post(`${controller}/declineApplication`, payload);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: DECLINE_CLIENT_APPLICATION_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        onSuccess();

        yield put({
            type: DECLINE_CLIENT_APPLICATION_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: DECLINE_CLIENT_APPLICATION_ERROR,
            payload: error,
        });
    }
};

export const getClientApplicationStatusesByIdsSaga = function* ({payload}) {
    try {
        const {ids} = payload;
        const result = yield request.post(`${controller}/getApplicationStatusesByIds`, ids);

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_CLIENT_APPLICATION_STATUSES_BY_IDS_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_CLIENT_APPLICATION_STATUSES_BY_IDS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CLIENT_APPLICATION_STATUSES_BY_IDS_ERROR,
            payload: error,
        });
    }
};

export const getBitrixLeadByIdSaga = function* ({payload}) {
    try {
        const result = yield request.bff.get("/adm/client-applications/bitrix/lead/getById", {params: payload});

        const {errorMessage} = result;

        if (errorMessage) {
            toastError(errorMessage);

            yield put({
                type: GET_BITRIX_LEAD_BY_ID_ERROR,
                payload: errorMessage,
            });
            return {
                done: true,
            };
        }

        yield put({
            type: GET_BITRIX_LEAD_BY_ID_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_BITRIX_LEAD_BY_ID_ERROR,
            payload: error,
        });
    }
};

export function* saga() {
    yield all([
        takeEvery(CLIENTS_APPLICATION_GET_PAGE_REQUEST, clientsApplicationGetPageSaga),
        takeEvery(CONFIRM_CLIENT_APPLICATION_REQUEST, confirmClientApplicationSaga),
        takeEvery(DECLINE_CLIENT_APPLICATION_REQUEST, declineClientApplicationSaga),
        takeEvery(GET_CLIENT_APPLICATION_STATUSES_BY_IDS_REQUEST, getClientApplicationStatusesByIdsSaga),
        takeEvery(GET_BITRIX_LEAD_BY_ID_REQUEST, getBitrixLeadByIdSaga),
    ]);
}
