import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
    createAtJobBoardRecruitmentVacancy,
    importResponsesFromJobBoardRecruitmentVacancy,
    updateFromJobBoardRecruitmentVacancy,
} from "../../../../../../ducks/bff/recruitment/vacancy/actionCreators";

import {useModal} from "../../../../../../hooks/useModal";

import {getUserRole, isVisibleRecruitmentActions} from "../../../../../../utils/access";
import {toastSuccess} from "../../../../../../utils/toastHelper";

import {COMPONENT} from "../../../../../../components/ActualComponents/MediaControls/constants";
import {
    ADMIN,
    CLIENT_ADMIN,
} from "../../../../../../constants/roles";
import {
    RECRUITMENT_SOURCE_JOB_BOARD,
    RECRUITMENT_VACANCY_STATUS,
} from "../../../constants";

import {downloadDocument} from "../../../../../../ducks/documents";

const useRecruitmentVacancyJobBoardListAction = (props) => {
    const {
        clientId,
        vacancyId,
        fetchInfo,
        naimixVacancyCard,
    } = props;

    const [confirmData, setConfirmData] = useState({});
    const [createVacancyData, setCreateVacancyData] = useState({});
    const [naimixHideDetails, setNaimixHideDetails] = useState(true);

    const dispatch = useDispatch();

    const role = getUserRole();
    const isAccessAction = isVisibleRecruitmentActions();

    const {
        onOpenModal,
        onCloseModal,
        modalData,
    } = useModal();

    const getMediaControls = (item) => {
        const {
            jobBoardType,
            externalId,
        } = item;

        const disabledAction = ![
            RECRUITMENT_VACANCY_STATUS.PUBLISHED.VALUE,
        ].includes(naimixVacancyCard.status);

        if (jobBoardType === RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX) {
            return {
                renderCount: {
                    desktop: 1,
                    tablet: 0,
                    mobile: 0,
                },
                buttons: [
                    {
                        component: COMPONENT.BUTTON,
                        props: {
                            onClick: () => {
                                setConfirmData({
                                    content: "Вы действительно хотите создать объявление по вакансии?",
                                    onConfirm: publishAtNaimixRecruitmentVacancy,
                                });
                            },
                            color: "green",
                            children: "Опубликовать в маркетплейсе",
                            disabled: disabledAction,
                        },
                        visible: !item.externalId && isAccessAction,
                    },
                ],
            };
        }

        return {
            renderCount: {
                desktop: 1,
                tablet: 0,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setCreateVacancyData({
                                jobBoardType,
                            });
                        },
                        color: "green",
                        children: "Создать на джоб-борде",
                        disabled: disabledAction,
                    },
                    visible: !item.externalId && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setCreateVacancyData({
                                jobBoardType,
                                isUpdate: true,
                                data: {
                                    billingType: item.billingType,
                                    type: item.type,
                                    publishType: item.publishType,
                                    businessArea: item.businessArea,
                                    city: item.city,
                                    profession: item.profession,
                                    published: item.published,
                                    experience: item.experience,
                                    schedule: item.schedule,
                                },
                            });
                        },
                        color: "green",
                        children: "Обновить параметры вакансии на джоб-борде",
                        disabled: disabledAction,
                    },
                    visible: Boolean(item.externalId) && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            setConfirmData({
                                content: "Вы действительно хотите обновить вакансию на площадке? В случае подтверждения все параметры вакансии на площадке будут изменены на заданные параметры вакансии на джоб-борде",
                                onConfirm: () => updateFromJobBoardVacancy(jobBoardType),
                            });
                        },
                        color: "green",
                        children: "Обновить параметры вакансии из джоб-борда",
                        disabled: disabledAction,
                    },
                    visible: Boolean(item.externalId) && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => importResponsesFromJobBoardVacancy(jobBoardType),
                        color: "green",
                        children: "Выгрузить отклики из джоб-борда",
                        disabled: disabledAction,
                    },
                    visible: Boolean(item.externalId) && isAccessAction,
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            onOpenModal({
                                isOpenChangeAccount: true,
                                account: item,
                            });
                        },
                        children: "Изменить аккаунт",
                    },
                    visible: Boolean(item.canChangeJobBoardAccount),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => onOpenModal({
                            isOpenWebhooksModal: true,
                            externalId,
                            jobBoardType,
                        }),
                        children: "Подписки",
                        disabled: disabledAction,
                    },
                    visible: Boolean(item.externalId) && [ADMIN, CLIENT_ADMIN].includes(role),
                },
            ],
        };
    };

    const downloadFile = ({fileName, filePath}) => {
        if (!filePath) {
            return;
        }

        dispatch(downloadDocument({
            isDownload: true,
            fileName,
            downloadLink: filePath,
        }));
    };

    const updateFromJobBoardVacancy = (jobBoardType) => {
        dispatch(updateFromJobBoardRecruitmentVacancy({
            vacancyId,
            jobBoardType,
            onSuccess: () => {
                toastSuccess("Параметры вакансии на площадке успешно обновлены");
                fetchInfo();
            },
        }));
    };

    const importResponsesFromJobBoardVacancy = (jobBoardType) => {
        dispatch(importResponsesFromJobBoardRecruitmentVacancy({
            vacancyId,
            jobBoardType,
            onSuccess: () => {
                fetchInfo();
            },
        }));
    };

    const publishAtNaimixRecruitmentVacancy = () => {
        dispatch(createAtJobBoardRecruitmentVacancy({
            clientId,
            vacancyId,
            jobBoardParams: {
                jobBoardType: RECRUITMENT_SOURCE_JOB_BOARD.NAIMIX,
            },
            onSuccess: () => {
                fetchInfo();
                toastSuccess("Объявление по вакансии успешно создано");
            },
        }));
    };

    return {
        getMediaControls,
        confirmData,
        setConfirmData,
        downloadFile,
        createVacancyData,
        setCreateVacancyData,
        naimixHideDetails,
        setNaimixHideDetails,
        modalData,
        onCloseModal,
    };
};

export default useRecruitmentVacancyJobBoardListAction;