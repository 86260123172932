import React, {ReactNode, useState} from "react";

import {ReactComponent as IconChevron} from "../../../images/chevron-down.svg";
import SortElement from "../../SortElement";
import NmCheckboxV2, {TNmCheckboxParams} from "../NmCheckboxV2";
import Text from "../Text";

import {OptionType} from "../../../containers/document-management/document-management-statement/list/item/utils/getOptions";
import bem from "../../../utils/bem";
import {getClassNames} from "../../../utils/classNames";

import "./style.sass";

type TListItem = {
    isSelected: boolean,
    isShowChevronButton?: boolean,
    [key: string]: any,
}

interface ICheckboxAccordionList {
    list: TListItem[]
    onSelectedRows: (list: TListItem[], index?: number) => void,
    isShowAllCheckbox?: boolean,
    isModalSortSize?: boolean,
    sort?: boolean
    sortOptions?: Array<OptionType> | [],
    onClickSort?: (option: OptionType) => void,
    classNameHeaderName?: string,
    allCheckboxLabel?: string | ReactNode,
    className?: string,
    classNameContentContainer?: string,
    isRowPositionRelative?: boolean
}

const CheckboxAccordionList = (props: ICheckboxAccordionList) => {
    const {
        list,
        isModalSortSize,
        onSelectedRows,
        isShowAllCheckbox = true,
        sort = false,
        sortOptions = [],
        onClickSort = () => {},
        classNameHeaderName = "",
        allCheckboxLabel = "Выбрать все",
        className,
        classNameContentContainer,
        isRowPositionRelative = false,
    } = props;
    const [block, element] = bem("checkbox-accordion-list", className);
    const [openIndex, setOpenIndex] = useState(-1);

    const onSelectAllRows = (e: React.ChangeEvent<HTMLInputElement>, {checked}: TNmCheckboxParams) => {
        e.stopPropagation();

        const _rows = list.map(item => ({
            ...item,
            isSelected: item.showCheckBox !== undefined && item.showCheckBox && checked && !item.disabledCheckBox,
        }
        ));

        return onSelectedRows(_rows);
    };

    const onSelectRow = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, index: number) => {
        const _list = [...list];
        _list[index]["isSelected"] = checked;

        return onSelectedRows(_list, index);
    };

    const getDisabledHeaderCheckbox = () => {
        const length = list.filter(value => value.disabledCheckBox).length;

        return length === list.length;
    };

    const getAllCheckboxValue = () => {
        const shownCheckboxCount = list.filter(value => value.showCheckBox && !value.disabledCheckBox).length;
        const selectedCheckBoxCount = list.filter(value => value.isSelected).length;

        return shownCheckboxCount !== 0 && shownCheckboxCount === selectedCheckBoxCount;
    };

    const toggleChevron = (index: number) => {
        if (openIndex === index) {
            setOpenIndex(-1);

            return;
        }

        setOpenIndex(index);
    };

    return (
        <div className={block()}>
            {
                sort &&
                <SortElement
                    isModalSortSize={isModalSortSize}
                    className="checkbox-list__sort"
                    options={sortOptions}
                    onClickItem={onClickSort}
                />
            }
            {isShowAllCheckbox && <div className={element("row")}>
                <NmCheckboxV2
                    label={allCheckboxLabel}
                    disabled={getDisabledHeaderCheckbox()}
                    checked={getAllCheckboxValue()}
                    onChange={onSelectAllRows}
                />
            </div>
            }
            <div
                className={getClassNames([
                    "checkbox-accordion-list__content-container",
                    classNameContentContainer,
                ])}
            >
                {
                    list.map((value, index) => {
                        const {
                            isSelected,
                            name,
                            content,
                            additionalHeaderContent,
                            isShowChevronButton = true,
                            showCheckBox,
                            disabledCheckBox,
                        } = value;

                        const isOpen = openIndex === index;

                        return (
                            <div
                                key={index}
                                className={element("row", {active: isOpen, positionRelative: isRowPositionRelative})}
                            >
                                <div className={element("header")}>
                                    {
                                        showCheckBox &&
                                        <NmCheckboxV2
                                            onChange={(event, {checked}) => {
                                                onSelectRow(event, checked, index);
                                            }}
                                            disabled={disabledCheckBox}
                                            className={element("checkbox")}
                                            checked={isSelected}
                                        />
                                    }
                                    <Text
                                        level="3"
                                        className={`${element("name", {cropped: !isOpen})} ${classNameHeaderName}`}
                                    >
                                        {name}
                                    </Text>
                                    {additionalHeaderContent}
                                    {
                                        isShowChevronButton &&
                                        <IconChevron
                                            onClick={() => {
                                                toggleChevron(index);
                                            }}
                                            className={element("chevron", {open: isOpen})}
                                        />
                                    }
                                </div>
                                {
                                    isOpen &&
                                    <Text
                                        level="2"
                                        className={element("content")}
                                    >
                                        {content}
                                    </Text>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default CheckboxAccordionList;