import * as yup from "yup";

import {isInteger} from "../../../../../utils/mathHelper";
import {validateAge} from "../../../../../utils/validate";
import {transformYupFormattedAmountToNumber} from "../../../../../utils/yupTransforms";

import {VALIDATIONS_MESSAGE} from "../../../../../constants/validationsYup";

const MIN_NUMBER_OF_YEARS = 14;

const validationSchema = () => {
    const salaryValidation = yup.number()
        .nullable()
        .transform(transformYupFormattedAmountToNumber)
        .min(0, "Минимальное допустимое значение равно 0")
        .max(999999999, "Максимальное допустимое значение равно 999 999 999")
        .test("check", "Только целые числовые значения", function (value) {
            if (!value) {
                return true;
            }

            return isInteger(value);
        });

    return yup.object().shape({
        recruiterId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        title: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .max(100, "Не более 100 символов"),
        description: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED)
            .min(5, "Не менее 5 символов")
            .max(5000, "Не более 5000 символов"),
        specialityId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        publishDateFrom: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        publishDateTo: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        funnelId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        workAddressFiasId: yup.string()
            .nullable()
            .required(VALIDATIONS_MESSAGE.REQUIRED),
        ageFrom: yup.string()
            .nullable()
            .test("age-check", "Минимальное значение 14", (value) => validateAge(value, MIN_NUMBER_OF_YEARS))
            .test("age", "\"От\" не может быть больше \"До\"", function(ageFrom) {
                if (!ageFrom || !this.parent.ageTo) {
                    return true;
                }

                return +ageFrom <= +this.parent.ageTo;
            }),
        ageTo: yup.string()
            .nullable()
            .test("age-check", "Минимальное значение 14", (value) => validateAge(value, MIN_NUMBER_OF_YEARS)),
        salaryFrom: salaryValidation
            .test("check-value", "\"От\" не может быть больше \"До\"", function (salaryFrom) {
                if (!salaryFrom || !this.parent.salaryTo) {
                    return true;
                }

                return salaryFrom <= this.parent.salaryTo;
            }),
        salaryTo: salaryValidation,
    });
};

export default validationSchema;