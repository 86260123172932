import React, {FC, useState} from "react";

import NmTitle from "../../NmTitle";
import NmModal from "../NmModal";
import ShowMoreText, {IShowMoreTextProps} from "./ShowMoreText";

import bem from "../../../utils/bem";

import "./style.sass";

interface INmShowMoreText extends IShowMoreTextProps {
    anchor: "green" | "blue",
    type?: "page" | "list",
    title?: string,
    isLoaded?: boolean,
    openExpandedModal?: boolean
}

const NmShowMoreText: FC<INmShowMoreText> = (props) => {
    const {
        anchor,
        type = "list",
        children,
        title,
        isLoaded = true,
        ...otherProps
    } = props;
    const [,element] = bem("nm-show-more-text");
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(!open);
    };

    if (!isLoaded) {
        return null;
    }

    return (
        <>
            {
                open &&
                <NmModal
                    size="md"
                    header={
                        title &&
                        <NmTitle
                            className={element("modal-title")}
                            size="lg"
                        >
                            {title}
                        </NmTitle>
                    }
                    classNameContent={element("modal-content")}
                    children={children}
                    onClose={onOpen}
                />
            }
            <ShowMoreText
                {...otherProps}
                anchorClass={element("anchor", {
                    anchor,
                    type,
                })}
                onClick={onOpen}
                children={children}
            />
        </>
    );
};

export default NmShowMoreText;