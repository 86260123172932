import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {usePagination} from "../../../../hooks/usePagination";

import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../../utils/localstorage";
import {toastError} from "../../../../utils/toastHelper";

import {isClientUser} from "../../../../constants/roles";
import {TASK_CHAT_SEARCH_ERROR_CODE} from "../../../crowd/task-registry/components/edit-form/constants";

import {
    crmChatGetMessageCount,
    currentChatSelector,
    getChatList,
    getCrmChatWithNamemix,
    getPageCrowdChat,
    updateFieldChat,
} from "../../../../ducks/chat";

export function useFetchList(params) {
    const {
        clientId,
        items,
        nameMixChat,
        setNameMixChat,
        filter,
        isFiltersReset,
        setLoading,
        chatIdFilter,
        contractorIdFilter,
        isCrowd,
    } = params;

    const chatInfo = useSelector(currentChatSelector);

    const currentUserId = ls(CURRENT_CLIENT_USER_ID);
    const role = ls(USER_ROLE);

    const dispatch = useDispatch();

    const {
        pageNum,
        pageSize,
        setPagination,
        onChangePageSize,
        onPaginationChange,
    } = usePagination("nm-page");

    useEffect(() => {
        return () => {
            setItems([]);
        };
    }, []);

    useEffect(() => {
        fetchList();
    }, [pageNum, pageSize, filter]);

    useEffect(() => {
        if (isFiltersReset) {
            setItems([]);

            setPagination({
                pageSize,
                pageNum: 1,
            });
        }
    }, [isFiltersReset]);

    useEffect(() => {
        setItems(items.map((item) => chatInfo.chatId === item.chatId ? chatInfo : item));
    }, [chatInfo.locked]);

    useEffect(() => {
        if (chatInfo.isNamemixChat && chatInfo.chatId) {
            setNameMixChat({
                ...nameMixChat,
                ...chatInfo,
            });
        }
    }, [chatInfo]);

    useEffect(() => {
        if (isClientUser(role) && !isCrowd) {
            !chatIdFilter && setCurrentChat(nameMixChat);
            dispatch(getCrmChatWithNamemix({
                userId: currentUserId,
                getResult: (result) => {
                    if (!result?.chatId) {
                        dispatch(updateFieldChat({
                            isShowNaimixDefaultMessage: true,
                        }));

                        return;
                    }

                    const nameMixChatInfo = {
                        ...nameMixChat,
                        ...result,
                    };

                    setNameMixChat(nameMixChatInfo);

                    if (!chatIdFilter) {
                        setCurrentChat(nameMixChatInfo);
                    }
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (nameMixChat.chatId) {
            getNamemixUnreadMessageCount(nameMixChat.chatId);
        }
    }, [nameMixChat.chatId]);

    const setItems = (chatList) => {
        dispatch(updateFieldChat({chatList}));
    };

    function getNamemixUnreadMessageCount(chatId) {
        dispatch(crmChatGetMessageCount({
            chatId,
            readFilter: false,
            getResult: (result) => {
                setNameMixChat({
                    ...nameMixChat,
                    unreadMessageCount: result,
                });
            },
        }));
    }

    function fetchList() {
        const _items = isFiltersReset ? [] : items;
        const reqData = {
            ...filter,
            pageNum,
            pageSize,
            getResult: ({chats}) => {
                setItems(pageNum !== 1 ? [
                    ..._items,
                    ...chats,
                ] : chats);

                if (isCrowd && contractorIdFilter && !_items.length && chats.length) {
                    setCurrentChat(chats[0]);
                }

                setLoading(false);
            },
        };

        if (isCrowd) {
            dispatch(getPageCrowdChat({
                clientId,
                getError: ({errorCode, errorMessage}) => {
                    if (errorCode === TASK_CHAT_SEARCH_ERROR_CODE.WRONG_AMOUNT_OF_SYMBOLS) {
                        toastError("Введите не менее 3х символов");

                        return;
                    }

                    toastError(errorMessage);
                },
                ...reqData,
            }));

            return;
        }

        dispatch(getChatList({
            clientIdFilter: clientId,
            ...reqData,
        }));
    }

    function setCurrentChat(currentChat) {
        dispatch(updateFieldChat({
            currentChat,
        }));
    }

    return {
        pageNum,
        pageSize,
        onChangePageSize,
        onPaginationChange,
        setPagination,
        setCurrentChat,
        setItems,
    };
}