import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import NmLoader from "../NmLoader";
import Dialog from "./Dialog";

import {useFetchList} from "../../containers/chats/chat-client-list/hooks/useFetchList";
import {useLazyLoading} from "../../hooks/useLazyLoading";

import bem from "../../utils/bem";
import {ls, USER_ROLE} from "../../utils/localstorage";

import {naimixSupportPhone} from "../../constants/chats";
import {isClientUser} from "../../constants/roles";

import {
    chatListSelector,
    chatListTotalCountSelector,
    chatLoadingChatsSelector,
    currentChatSelector,
    updateFieldChat,
} from "../../ducks/chat";
import {getChatById} from "../../ducks/chatList";

import "./style.sass";

function DialogList(props) {
    const {
        className,
        clientId,
        isOpenFilter,
        filter,
        isFiltersReset,
        chatIdFilter,
        contractorIdFilter,
        setLoading,
        isCrowd,
    } = props;

    const [block, element] = bem("dialog-list", className);

    const totalCount = useSelector(chatListTotalCountSelector);
    const chatInfo = useSelector(currentChatSelector);
    const items = useSelector(chatListSelector);
    const loading = useSelector(chatLoadingChatsSelector);

    const dispatch = useDispatch();

    const [nameMixChat, setNameMixChat] = useState({
        isNamemixChat: true,
        attached: true,
        phone: naimixSupportPhone,
    });

    const role = ls(USER_ROLE);
    const {chatId} = chatInfo;

    const {
        pageNum,
        pageSize,
        setPagination,
        setCurrentChat,
        setItems,
    } = useFetchList({
        clientId,
        items,
        nameMixChat,
        setNameMixChat,
        filter,
        isFiltersReset,
        setLoading,
        chatIdFilter,
        contractorIdFilter,
        isCrowd,
    });

    const appendItems = useCallback(() => {
        if (items.length < totalCount) {
            setPagination({
                pageSize,
                pageNum: pageNum + 1,
            });
        }
    }, [items, totalCount]);

    const [onScroll, containerRef] = useLazyLoading({
        onIntersection: appendItems,
        delay: 1200,
    });

    useEffect(() => {
        if (chatIdFilter) {
            dispatch(getChatById({
                chatId: chatIdFilter,
                getResult: (result) => {
                    setCurrentChat(result);
                },
            }));
        }
    }, []);

    const onClickBlock = (item) => {
        if (chatId === item.chatId && !isEmpty(chatInfo)) {
            return;
        }

        dispatch(updateFieldChat({
            messageList: [],
            sessionMessageUploadingStatuses: [],
        }));

        setCurrentChat(item);
        if (item.unreadMessageCount) {
            setTimeout(() => resetUnreadMessageCount(item.chatId), 3000);
        }
    };

    const resetUnreadMessageCount = (chatId) => {
        setItems(items.map((item) => {
            const messageCountParams = chatId === item.chatId ? {
                unreadMessageCount: 0,
                markedUnread: false,
            } : {};

            return ({
                ...item,
                ...messageCountParams,
            });
        }));
    };

    function getList() {
        const crmChats = isClientUser(role) && !isCrowd ? [nameMixChat] : [];
        const list = [
            ...crmChats,
            ...items,

        ];

        return list.map((item) => {
            const {
                contractorId,
                contractorFio,
                lastMessageDate,
                lastMessage,
                isNamemixChat,
                attached,
                unreadMessageCount,
                chatId: _chatId,
            } = item;

            return (<Dialog
                className={element("item")}
                isNamemixChat={isNamemixChat}
                lastMessage={lastMessage || {}}
                unreadMessageCount={unreadMessageCount}
                date={lastMessageDate}
                name={isNamemixChat ? "Служба поддержки Наймикс" : contractorFio}
                contractorId={contractorId}
                onClickBlock={() => onClickBlock(item)}
                isActive={
                    chatId === _chatId ||
                    (!chatId && isNamemixChat)
                }
                attached={attached}
                chat={item}
            />);
        });
    }


    return (
        <div
            className={block({"open-filter": isOpenFilter})}
            ref={containerRef}
            onScroll={onScroll}
        >
            {getList()}
            {
                loading &&
                <NmLoader
                    inverted={true}
                    active={true}
                />
            }
        </div>
    );
}

export default DialogList;