import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import {debounce} from "lodash";

import NmConfirmV2 from "../../../components/ActualComponents/NmConfirmV2";
import NmDateRangePickerV2 from "../../../components/ActualComponents/NmDateRangePickerV2";
import NmDropdownV2 from "../../../components/ActualComponents/NmDropdownV2";
import NmEmptyPageV2 from "../../../components/ActualComponents/NmEmptyPageV2";
import NmForm from "../../../components/ActualComponents/NmForm";
import NmInputV2 from "../../../components/ActualComponents/NmInputV2";
import NmListCard from "../../../components/ActualComponents/NmList/Card";
import CheckboxList from "../../../components/CheckboxList";
import ExtLink from "../../../components/ExtLink";
import LogoThumbnail from "../../../components/LogoThumbnail";
import NmBadge from "../../../components/NmBadge";
import NmButton from "../../../components/NmButton";
import NmPage from "../../../components/NmPage";
import Task from "../../../components/NmTask";
import NmTitle from "../../../components/NmTitle";
import {ReactComponent as LogoIcon} from "../../../images/company-logo.svg";

import {isNMUsers} from "../../../utils/access";
import bem from "../../../utils/bem";
import dateFormat, {convertUtcToLocal, formatDateWithoutTime} from "../../../utils/dateFormat";
import {downloadByUrl} from "../../../utils/downloadBlob";
import {CURRENT_CLIENT_USER_ID, ls, USER_ROLE} from "../../../utils/localstorage";
import {getFullName} from "../../../utils/stringFormat";
import {handleFormString, isNullOrWhitespace} from "../../../utils/stringHelper";
import {toastWarning} from "../../../utils/toastHelper";
import {getFinanceExportRequestData} from "./utils/dto";

import {COMPONENT} from "../../../components/ActualComponents/MediaControls/constants";
import {ORDER_WORK_REPORT_TYPE, ORDER_WORK_REPORT_TYPE_TRANSLATE} from "../../../constants/finance";
import {
    FINANCE_EXPORT_DEPOSIT_TYPE_FILTER_OPTIONS,
    FINANCE_EXPORT_OPTION_XML,
    FINANCE_EXPORT_OVM_NOTIFICATION_TYPE,
    FINANCE_EXPORT_OVM_NOTIFICATION_TYPE_OPTIONS,
    FINANCE_EXPORT_PAGE_TYPE,
    FINANCE_EXPORT_UPLOAD_TYPE,
    formatFileDict,
    formatFileOptions,
    PERIOD_OPTIONS_OPTIONS,
    UPLOAD_STATUS,
    UPLOAD_TYPE,
} from "../../../constants/financeExport";
import {FINANCE_PIVOT_LINK_PARAMS} from "../../../constants/financePivot";
import {LINK_CLIENT_FINANCE_EXPORT_FULL, LINK_CLIENT_INFO, LINK_FINANCE_EXPORT_FULL} from "../../../constants/links";
import {PROMOCODE_TYPE} from "../../../constants/promocode/dict";
import {
    ADMIN,
    CLIENT_ACCOUNTANT,
    CLIENT_ADMIN,
    FOREMAN,
    NM_CHIEF_ACCOUNTANT,
    NM_CONSULTANT,
    NM_COORDINATOR,
    NM_MANAGER,
    NM_OPERATOR,
    NM_PARTNER,
    OBJECT_MANAGER,
    PROJECT_MANAGER,
    RNKO,
} from "../../../constants/roles";

import {history} from "../../../store/configureStore";

import {
    clientListByPartnerOptionsSelector,
    clientListOptimizedOptionsSelector,
    getClientsByPartner,
    getOptimizedClientList,
    updateFieldClientStore,
} from "../../../ducks/client";
import {clientCurrentMemberSelector} from "../../../ducks/clientMember";
import {getClientPropertiesCardSelector} from "../../../ducks/clientProperties";
import {getFileLink} from "../../../ducks/documents";
import {
    edoAccessListSelector,
    getEdoAccessList,
} from "../../../ducks/edocuments";
import {
    addFinanceExportSuccessTaskSelector,
    addFinanceExportTask,
    financeExportDataSelector,
    financeExportDataTaskIdsSelector,
    financeExportLoadingSelector,
    financeExportTotalCountSelector,
    financeExportTotalPagesSelector,
    getFinanceExportPage,
    getReferralPromoCodeClientsRewardsExportAvailable,
    referralPromoCodeClientsRewardsExportAvailableSelector,
    updateFinanceStore,
} from "../../../ducks/financeExport";
import {
    cancelExportById,
    getProgressExport,
    progressExportDataSelector,
} from "../../../ducks/job";
import {getClientListByPromocodeOptionSelector, getClientsByPromocode} from "../../../ducks/promocode";

import "./style.sass";

import {EDO_ACCESS_RIGHTS} from "../../../constants/document-management/document-list";

class FinanceExport extends Component {
    constructor(props) {

        super(props);

        const {pageNumber, pageSize, clientId} = props.match.params;

        this.clientId = clientId;

        this.role = ls(USER_ROLE);
        this.currentClientUserId = ls(CURRENT_CLIENT_USER_ID);

        const defaultUploadType = this.role !== NM_PARTNER && this.uploadTypeDict && this.uploadTypeDict.length ?
            this.uploadTypeDict[0].value :
            "";

        this.state = {
            pageNum: pageNumber || 1,
            pageSize: Number(pageSize) || 25,
            pageSizes: [25, 50, 100],
            uploadType: defaultUploadType,
            exportEventType: "",
            formatType: formatFileOptions[0].value,
            showPeriodBlock: true,
            nameSubstringFilter: "",
            orderContractPaymentType: ORDER_WORK_REPORT_TYPE.SMZ,
            fromClientId: "",
            period: "",
            promocodeFilter: "",
            openConfirmWindow: false,
            confirmData: {},
        };

        const [, element] = bem("finance-export");

        this.element = element;
    }

    componentDidMount() {
        const {
            getEdoAccessList,
            getReferralPromoCodeClientsRewardsExportAvailable,
        } = this.props;

        this.fetchFinanceExportPage();

        if (isNullOrWhitespace(this.clientId)) {
            this.fetchClientListForFilter();
        }

        if (this.role === NM_PARTNER) {
            getReferralPromoCodeClientsRewardsExportAvailable();
        }

        getEdoAccessList();

        this.debounceFetchFinanceExportProgress = debounce(this.fetchFinanceExportProgress, 3000);
    }

    componentDidUpdate(prevProps) {
        const {
            isAddedTaskSuccess,
            updateFinanceStore,
            taskInProgressIds,
        } = this.props;

        if (isAddedTaskSuccess) {
            updateFinanceStore({"addTaskSuccess": false});
            this.setState({
                pageNum: 1,
            }, this.fetchFinanceExportPage);
        }

        if (taskInProgressIds.length !== 0) {
            this.debounceFetchFinanceExportProgress();
        }
    }

    componentWillUnmount() {
        const {updateFieldClientStore} = this.props;

        if (isNullOrWhitespace(this.clientId)) {
            updateFieldClientStore("list", []);
        }

        this.debounceFetchFinanceExportProgress.cancel();
    }

    getAccessTypeEdo = (accessEdmType) => {
        if (isNullOrWhitespace(this.clientId)) {
            return false;
        }

        const {
            clientProperties: {
                edmAvailable,
            },
            edoAccessList,
        } = this.props;

        if ([ADMIN, NM_MANAGER, NM_COORDINATOR, NM_CHIEF_ACCOUNTANT].includes(this.role)) {
            return edmAvailable;
        }

        return edmAvailable && (edoAccessList.includes(accessEdmType));
    };

    get uploadTypeDict() {
        const {
            referralPromoCodeClientsRewardsExportAvailable,
            clientProperties: {
                withoutContract,
            },
        } = this.props;

        if (this.role === NM_PARTNER) {
            const referralPromoCodeClients = referralPromoCodeClientsRewardsExportAvailable ? [
                UPLOAD_TYPE.REFERRAL_PROMO_CODE_CLIENTS_REWARDS,
            ] : [];

            return [
                UPLOAD_TYPE.ORDERS_INFORMATION,
                UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS,
                UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS,
                UPLOAD_TYPE.PAYMENTS,
                ...referralPromoCodeClients,
            ];
        }

        if (this.role === NM_OPERATOR) {
            return [
                UPLOAD_TYPE.LEADS_FROM_CLIENTS,
                UPLOAD_TYPE.TICKET_EXPORT,
            ];
        }

        const adminPayments = this.role === ADMIN ? [
            UPLOAD_TYPE.PAYMENTS_1C,
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C,
        ] : [];

        const payments1cMultiBanking = [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role) ? [
            UPLOAD_TYPE.PAYMENTS_1C_MULTI_BANKING,
        ] : [];
        const paymentsCivil1cMultiBanking = [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role) ? [
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C_MULTI_BANKING,
        ] : [];
        const taxPaymentsNpd = [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role) ? [
            UPLOAD_TYPE.TAX_PAYMENTS_NPD,
            UPLOAD_TYPE.SYSTEM_TRANSACTION_TKB_EXPORT,
        ] : [];
        const paymentsIndividual1cMultiBanking = [ADMIN, NM_CHIEF_ACCOUNTANT].includes(this.role) ? [
            UPLOAD_TYPE.PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING,
        ] : [];

        const typeDict = isNullOrWhitespace(this.clientId) ?
            [
                UPLOAD_TYPE.PAYMENTS,
                UPLOAD_TYPE.PAYMENTS_CIVIL,
                UPLOAD_TYPE.PAYMENTS_INDIVIDUAL,
                ...adminPayments,
                UPLOAD_TYPE.PATENT_PAYMENTS,
                UPLOAD_TYPE.SUMMARY,
                UPLOAD_TYPE.SUMMARY_CIVIL,
                UPLOAD_TYPE.CONTRACTORS,
                UPLOAD_TYPE.ALL_PAYMENTS,
                UPLOAD_TYPE.ORDERS_INFORMATION,
                UPLOAD_TYPE.CONTRACTORS_ON_ORDERS,
                UPLOAD_TYPE.LEADS_FROM_CLIENTS,
                UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS,
                UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS,
                UPLOAD_TYPE.CLIENT_COMMISSION_HISTORY_EXPORT,
                UPLOAD_TYPE.PUBLIC_CLIENT_APPLICATION_EXPORT,
                UPLOAD_TYPE.ATOL_RECEIPTS_EXPORT,
                UPLOAD_TYPE.CROWD_TASK_EXPORT,
                UPLOAD_TYPE.CROWD_TASK_ANALYTICS_EXPORT,
            ]
            :
            [
                UPLOAD_TYPE.PAYMENTS,
                UPLOAD_TYPE.PAYMENTS_CIVIL,
                UPLOAD_TYPE.PAYMENTS_INDIVIDUAL,
                ...adminPayments,
                UPLOAD_TYPE.PATENT_PAYMENTS,
                UPLOAD_TYPE.ORDERS_INFORMATION,
                UPLOAD_TYPE.CONTRACTORS_ON_ORDERS,
                UPLOAD_TYPE.CONTRACTORS_PAYMENTS,
                UPLOAD_TYPE.PAYMENT_CONFIRMATION,
                UPLOAD_TYPE.RESPONSE_ANALYTICS,
                UPLOAD_TYPE.ORDERS_ANALYTICS,
                UPLOAD_TYPE.OBJECT_ORDER_WORK_REPORT,
                UPLOAD_TYPE.MY_CONTRACTORS,
                UPLOAD_TYPE.ACCEPTED_CONTRACTORS_FOR_ORDERS,
                UPLOAD_TYPE.DECLINED_CONTRACTORS_FOR_ORDERS,
                UPLOAD_TYPE.CHECK_IN_OUT_EXPORT,
                UPLOAD_TYPE.CHECK_IN_OUT_EXPORT_CROWD_TASK,
                UPLOAD_TYPE.CHECK_IN_OUT_LIST_EXPORT,
                UPLOAD_TYPE.CROWD_CHECK_IN_OUT_LIST_EXPORT,
                UPLOAD_TYPE.ANALYTICS_INVITE_CONTRACTORS,
                UPLOAD_TYPE.FRAME_CONTRACTS_EXPORT,
                UPLOAD_TYPE.CLIENT_DEPOSIT,
                UPLOAD_TYPE.CLIENT_DEPOSIT_CIVIL,
                UPLOAD_TYPE.OBJECTS_DEPOSIT,
                UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT,
                UPLOAD_TYPE.CROWD_TASK_EXPORT,
                UPLOAD_TYPE.CROWD_TASK_ANALYTICS_EXPORT,
            ];

        if (isNMUsers() && ![RNKO, NM_CONSULTANT].includes(this.role)) {
            typeDict.push(UPLOAD_TYPE.CONTRACTOR_COMMISSION_HISTORY);
            typeDict.push(UPLOAD_TYPE.PROJECTS_AND_OBJECTS);
            typeDict.push(UPLOAD_TYPE.TICKET_EXPORT);
        }

        if ([ADMIN, NM_MANAGER, NM_CHIEF_ACCOUNTANT, NM_COORDINATOR, CLIENT_ADMIN, CLIENT_ACCOUNTANT].includes(this.role)) {
            typeDict.push(UPLOAD_TYPE.NPD_PAYMENTS_EXPORT);
            typeDict.push(UPLOAD_TYPE.RESPONSES_TO_ORDERS);
        }

        if (
            [
                ADMIN,
                NM_MANAGER,
                NM_CHIEF_ACCOUNTANT,
                NM_COORDINATOR,
                CLIENT_ADMIN,
                CLIENT_ACCOUNTANT,
                FOREMAN,
                PROJECT_MANAGER,
                OBJECT_MANAGER,
            ].includes(this.role)
        ) {
            typeDict.push(UPLOAD_TYPE.ORDER_TEMPLATES_EXPORT);
        }

        if ([CLIENT_ADMIN, PROJECT_MANAGER, CLIENT_ACCOUNTANT].includes(this.role)) {
            typeDict.push(UPLOAD_TYPE.PROJECTS_AND_OBJECTS);
        }

        const isAccessEdo = this.getAccessTypeEdo(EDO_ACCESS_RIGHTS.EDO_REPORT_VIEW);

        if (isAccessEdo) {
            typeDict.push(UPLOAD_TYPE.AGREEMENTS_EDO);
            typeDict.push(UPLOAD_TYPE.STAFF_EDO);
            typeDict.push(UPLOAD_TYPE.AGREEMENTS_EDO_WITH_PAYMENT);
        }

        if ([ADMIN, NM_MANAGER].includes(this.role)) {
            typeDict.push(UPLOAD_TYPE.PAID_APIS);
        }

        if (withoutContract) {
            return isAccessEdo ? [
                UPLOAD_TYPE.AGREEMENTS_EDO,
                UPLOAD_TYPE.STAFF_EDO,
            ] : [];
        }

        return [
            ...typeDict,
            ...payments1cMultiBanking,
            ...paymentsCivil1cMultiBanking,
            ...taxPaymentsNpd,
            ...paymentsIndividual1cMultiBanking,
        ];
    }

    fetchFinanceExportPage = () => {
        const {
            getFinanceExportPage,
            currentMember: {
                clientUserId,
            },
        } = this.props;

        const {
            pageNum,
            pageSize,
        } = this.state;

        getFinanceExportPage({
            clientId: this.clientId,
            clientUserId: this.role === NM_PARTNER ? clientUserId : undefined,
            pageType: FINANCE_EXPORT_PAGE_TYPE.COMMON,
            pageNum,
            pageSize,
        });
    };

    fetchFinanceExportProgress = () => {
        const {
            getProgressExport,
            taskInProgressIds,
        } = this.props;

        if (taskInProgressIds.length === 0) {
            return;
        }
        getProgressExport({
            reqData: taskInProgressIds,
            handleResponse: this.fetchFinanceExportPage,
        });
    };

    fetchClientListForFilter = () => {
        const {
            getOptimizedClientList,
            getClientsByPromocode,
            getClientsByPartner,
        } = this.props;
        const {
            nameSubstringFilter,
            promocodeFilter,
            uploadType,
        } = this.state;

        if (this.role === NM_PARTNER) {
            getClientsByPartner({
                nameSubstringFilter: handleFormString(nameSubstringFilter),
                pageNum: 1,
                pageSize: 25,
            });
            return;
        }

        if (![UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value, UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS.value].includes(uploadType)) {
            getOptimizedClientList({
                pageNum: 1,
                pageSize: 25,
                nameSubstringFilter: handleFormString(nameSubstringFilter),
            });
            return;
        }
        getClientsByPromocode({
            pageNum: 1,
            pageSize: 500,
            promocode: promocodeFilter || undefined,
            type: uploadType === UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value ? PROMOCODE_TYPE.CLIENTS : PROMOCODE_TYPE.CONTRACTORS,
        });
    };

    startFinanceUploadTask = () => {
        const {
            addFinanceExportTask,
            t,
        } = this.props;

        const {
            uploadType,
            fromDateFilter,
            toDateFilter,
            orderContractPaymentType,
            formatType,
            exportEventType,
            fromClientId,
            period,
            promocodeFilter,
        } = this.state;

        const conditionRequiredBothDates = [
            UPLOAD_TYPE.PATENT_PAYMENTS.value,
            UPLOAD_TYPE.PAYMENTS_INDIVIDUAL.value,
            UPLOAD_TYPE.PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING.value,
            UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value,
            UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS.value,
            UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value,
            UPLOAD_TYPE.CONTRACTORS.value,
            UPLOAD_TYPE.ATOL_RECEIPTS_EXPORT.value,
        ].includes(uploadType);

        const conditionRequiredEventType = [
            UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value,
        ].includes(uploadType);

        const conditionRequiredFromClientId = (
            !this.clientId &&
            [
                UPLOAD_TYPE.CROWD_TASK_EXPORT.value,
                UPLOAD_TYPE.PAYMENTS_INDIVIDUAL.value,
                UPLOAD_TYPE.PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING.value,
            ].includes(uploadType)
        ) || (
            this.role === NM_PARTNER &&
            ![UPLOAD_TYPE.REFERRAL_PROMO_CODE_CLIENTS_REWARDS.value].includes(uploadType)
        );

        const conditionRequiredOneDate = [
            UPLOAD_TYPE.PAYMENTS.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL.value,
            UPLOAD_TYPE.PAYMENTS_1C.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C.value,
            UPLOAD_TYPE.PAYMENTS_1C_MULTI_BANKING.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C_MULTI_BANKING.value,
        ].includes(uploadType);

        if (!fromDateFilter && !toDateFilter && conditionRequiredOneDate) {
            toastWarning(t("finance-export.warning-period"));

            return;
        }

        if (isNullOrWhitespace(fromClientId) && conditionRequiredFromClientId) {
            toastWarning("Заполните поле заказчик");

            return;
        }
        if (conditionRequiredEventType && !exportEventType) {
            toastWarning("Заполните тип события, за который необходимо сгенерировать отчет");
            return;
        }

        if (conditionRequiredBothDates && (!fromDateFilter || !toDateFilter)) {
            toastWarning("Заполните период, за который необходимо сгенерировать отчет");
            return;
        }

        const reqData = getFinanceExportRequestData({
            clientId: this.clientId,
            uploadType,
            toDateFilter,
            fromDateFilter,
            orderContractPaymentType,
            fromClientId,
            formatType,
            exportEventType,
            period,
            promocodeFilter,
        });

        addFinanceExportTask(reqData);
    };

    goTo(pageSize, pageNum = 1) {
        const {pathname} = this.props;
        const link = pathname.indexOf("client-card") !== -1 ? LINK_CLIENT_FINANCE_EXPORT_FULL : LINK_FINANCE_EXPORT_FULL;

        history.push(link
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGENUMBER_PARAM, pageNum)
            .replace(FINANCE_PIVOT_LINK_PARAMS.LINK_PAGESIZE_PARAM, pageSize)
            .replace(":clientId", this.clientId || ""),
        );
    }

    handleChangePageSize = pageSize => {
        this.goTo(pageSize);

        this.setState(
            {
                pageSize,
                pageNum: 1,
            },
            this.fetchFinanceExportPage,
        );
    };

    handlePaginationChange = (e, {"activePage": pageNum}) => {
        const {pageNum: pageNumOld, pageSize} = this.state;

        if (pageNum === pageNumOld) {
            return;
        }
        this.goTo(pageSize, pageNum);

        this.setState({
            pageNum,
        }, this.fetchFinanceExportPage);
    };

    getParams = (link) => {
        const parse = require("url-parse");

        const {query, pathname} = parse(link);

        const searchParams = new URLSearchParams(query);

        const fileName = searchParams.get("fileName");
        return {
            downloadLink: `${pathname}${query}`,
            fileName,
        };
    };

    handleDownload = (item) => {
        const {link} = item;
        const {getFileLink} = this.props;
        const {downloadLink, fileName} = this.getParams(link);

        getFileLink({
            downloadLink,
            onSuccess: (_link) => downloadByUrl(_link, fileName),
        });
    };

    cancelExport = (exportId) => {
        const {cancelExportById} = this.props;

        cancelExportById({
            exportId,
            onSuccess: this.fetchFinanceExportPage,
        });
    };

    getFormattedDate = (date) => {
        return isNullOrWhitespace(date) ? "-" : formatDateWithoutTime(convertUtcToLocal(new Date(date)));
    };

    getUploadTypeText({uploadType, registryNumber, customUploadType, vacancyNumber, orderContractPaymentType}) {
        if (customUploadType) {
            return customUploadType;
        }

        if (
            [
                UPLOAD_TYPE.REGISTRY_EXPORT.value,
                UPLOAD_TYPE.REGISTRY_EXPORT_WITH_TABLE.value,
                UPLOAD_TYPE.CROWD_ACT_REGISTRY_EXPORT.value,
            ].includes(uploadType)
        ) {
            const _registryNumber = registryNumber ? `№${registryNumber}` : "";

            return `${UPLOAD_TYPE[uploadType].text} ${_registryNumber}`;
        }

        if (uploadType === UPLOAD_TYPE.RECRUITMENT_RESPONSES_CANDIDATES_EXPORT.value) {
            const _vacancyNumber = vacancyNumber ? `№${vacancyNumber}` : "";

            return `${UPLOAD_TYPE[uploadType].text} ${_vacancyNumber}`;
        }

        if (uploadType === UPLOAD_TYPE.OBJECTS_DEPOSIT.value) {
            const _orderContractPaymentType = orderContractPaymentType ? `(${ORDER_WORK_REPORT_TYPE_TRANSLATE[orderContractPaymentType]})` : "";

            return `${UPLOAD_TYPE[uploadType].text} ${_orderContractPaymentType}`;
        }

        return UPLOAD_TYPE[uploadType] ? UPLOAD_TYPE[uploadType].text : uploadType;
    }

    getVisibleDownload({uploadStatus, link, clientUserId}) {
        const isSuccessExport = uploadStatus === UPLOAD_STATUS.SUCCESSFULLY.key && Boolean(link);
        if (NM_OPERATOR === this.role) {
            return clientUserId === this.currentClientUserId && isSuccessExport;
        }
        return isSuccessExport;
    }

    getMediaControls = (item) => {
        const {
            uploadStatus,
            uploadType,
            clientUserId,
            financeExportId,
        } = item;

        if (
            uploadType === FINANCE_EXPORT_UPLOAD_TYPE.ORDER_WORK_REPORT_CANCELLED_EXPORT &&
            ![CLIENT_ADMIN, PROJECT_MANAGER, OBJECT_MANAGER, NM_MANAGER, ADMIN, FOREMAN].includes(this.role)
        ) {
            return null;
        }

        return {
            renderCount: {
                desktop: 2,
                tablet: 2,
                mobile: 0,
            },
            buttons: [
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.handleDownload(item);
                        },
                        color: "light-green",
                        children: "Скачать",
                    },
                    visible: this.getVisibleDownload(item),
                },
                {
                    component: COMPONENT.BUTTON,
                    props: {
                        onClick: () => {
                            this.setState({
                                openConfirmWindow: true,
                                confirmData: {
                                    content: "Вы уверены, что хотите отменить формирование документа?",
                                    onConfirm: () => this.cancelExport(financeExportId),
                                    confirmButton: "Да",
                                    cancelButton: "Нет",
                                },
                            });
                        },
                        color: "grey",
                        children: "Отменить",
                    },
                    visible: uploadStatus === UPLOAD_STATUS.INITIALIZATION.key && ([ADMIN].includes(this.role) || clientUserId === this.currentClientUserId),
                },
            ],
        };
    };

    renderStatus = ({uploadStatus, taskId}) => {
        const {progressExportData} = this.props;

        const task = progressExportData.find(item => item.id === taskId);
        const progress = task?.progress ? `${JSON.parse(task.progress)?.percent || 0}%` : "0.00%";

        const text = uploadStatus === UPLOAD_STATUS.INITIALIZATION.key && progressExportData ?
            `${UPLOAD_STATUS[uploadStatus]?.text} ${progress}`
            : UPLOAD_STATUS[uploadStatus]?.text || uploadStatus;

        return (
            <NmBadge
                noWrap
                mod={UPLOAD_STATUS[uploadStatus]?.mod || "gray"}
                text={text}
            />
        );
    };

    getAvatar = ({logoImageUrl, fromClientName}) => {
        if (this.clientId) {
            return null;
        }

        if (!fromClientName || logoImageUrl) {
            return (
                <LogoThumbnail
                    noBorder={!fromClientName}
                    className="finance-export__logo"
                    size="sm"
                    src={logoImageUrl}
                />
            );
        }

        return <LogoIcon className="finance-export__logo finance-export__logo_empty" />;
    };

    getRows = () => {
        const {exportList} = this.props;

        return exportList.map(item => {
            const {
                documentsId,
                fromClientName,
                fromDate,
                toDate,
                formatType,
                createDatetime,
                lastName,
                firstName,
                patronymic,
                uploadType,
            } = item;

            const periodLabels = ![
                UPLOAD_TYPE.MAP_ENTRIES_EXPORT.value,
            ].includes(uploadType) ? [
                    {label: "Дата начала периода", text: this.getFormattedDate(fromDate)},
                    {label: "Дата окончания периода", text: this.getFormattedDate(toDate)},
                ] : [];

            return {
                ...item,
                key: documentsId,
                avatar: this.getAvatar(item),
                contentRow: (
                    <NmListCard
                        clientLogo={!this.clientId}
                        noDivider
                        alignItems="flex-start"
                        classNameMainContent="col-16 col-md-15"
                        labels={[
                            {
                                label: "Заказчик",
                                text: fromClientName ? this.renderName(item) : "Все заказчики",
                                noWrap: false,
                            },
                            ...periodLabels,
                            {
                                label: "Исполнитель",
                                text: getFullName(lastName, firstName, patronymic),
                                columnOnMobile: true,
                            },
                            {label: "Тип выгрузки", text: this.getUploadTypeText(item)},
                            {label: "Формат файла", text: formatType},
                        ]}
                        secondaryHeader={`Дата создания ${dateFormat(convertUtcToLocal(createDatetime))}`}
                        secondaryHeaderStatus={this.renderStatus(item)}
                        actionsClassName="col-1 justify-content-end"
                        mediaControls={this.getMediaControls(item)}
                    />
                ),
            };
        });
    };

    handleOnChangeDatepicker = (value, name) => {
        const _value = isNullOrWhitespace(value) ? undefined : dateFormat(value, "yyyy-MM-dd");

        this.setState({
            [name]: _value,
        });
    };

    handleOnChange = (e, {value, name}) => {
        const updatedStateFields = {};

        if (name === "uploadType") {
            if ([
                UPLOAD_TYPE.CLIENT_COMMISSION_HISTORY_EXPORT.value,
                UPLOAD_TYPE.CONTRACTOR_COMMISSION_HISTORY.value,
                UPLOAD_TYPE.PROJECTS_AND_OBJECTS.value,
            ].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if (value === UPLOAD_TYPE.ANALYTICS_INVITE_CONTRACTORS.value) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = undefined;
                updatedStateFields.promocodeFilter = undefined;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if (value === UPLOAD_TYPE.PAID_APIS.value) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = undefined;
                updatedStateFields.promocodeFilter = undefined;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.AGREEMENTS_EDO.value, UPLOAD_TYPE.AGREEMENTS_EDO_WITH_PAYMENT.value].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([
                UPLOAD_TYPE.LEADS_FROM_CLIENTS.value,
                UPLOAD_TYPE.TICKET_EXPORT.value,
                UPLOAD_TYPE.CROWD_TASK_EXPORT.value,
                UPLOAD_TYPE.CROWD_TASK_ANALYTICS_EXPORT.value,
            ].includes(value)) {
                updatedStateFields.fromClientId = "";
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if (value === UPLOAD_TYPE.STAFF_EDO.value) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([
                UPLOAD_TYPE.CHECK_IN_OUT_EXPORT.value,
                UPLOAD_TYPE.CHECK_IN_OUT_EXPORT_CROWD_TASK.value,
                UPLOAD_TYPE.ORDER_TEMPLATES_EXPORT.value,
            ].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([
                UPLOAD_TYPE.CHECK_IN_OUT_LIST_EXPORT.value,
                UPLOAD_TYPE.CROWD_CHECK_IN_OUT_LIST_EXPORT.value,
            ].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.PAYMENT_CONFIRMATION.value].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.PUBLIC_CLIENT_APPLICATION_EXPORT.value].includes(value)) {
                updatedStateFields.fromClientId = "";
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.CLIENT_DEPOSIT.value, UPLOAD_TYPE.CLIENT_DEPOSIT_CIVIL.value].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value].includes(value)) {
                updatedStateFields.period = "";
                updatedStateFields.exportEventType = FINANCE_EXPORT_OVM_NOTIFICATION_TYPE.CONCLUSION_CONTRACT;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.ATOL_RECEIPTS_EXPORT.value].includes(value)) {
                updatedStateFields.fromClientId = "";
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.period = "";
                updatedStateFields.formatType = formatFileDict.XML;
            } else if ([UPLOAD_TYPE.NPD_PAYMENTS_EXPORT.value].includes(value)) {
                updatedStateFields.fromClientId = "";
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.nameSubstringFilter = "";
                updatedStateFields.period = PERIOD_OPTIONS_OPTIONS[0].value;
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.OBJECTS_DEPOSIT.value].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromClientId = "";
                updatedStateFields.nameSubstringFilter = "";
                updatedStateFields.period = "";
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.MY_CONTRACTORS.value].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.period = PERIOD_OPTIONS_OPTIONS[0].value;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if (!this.isShowClientFilterByUploadType(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromClientId = "";
                updatedStateFields.nameSubstringFilter = "";
                updatedStateFields.period = PERIOD_OPTIONS_OPTIONS[0].value;
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value, UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS.value].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.fromClientId = "";
                updatedStateFields.formatType = formatFileOptions[0].value;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.STAFF_EDO.value].includes(value)) {
                updatedStateFields.showPeriodBlock = false;
                updatedStateFields.fromDateFilter = null;
                updatedStateFields.toDateFilter = null;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.RESPONSES_TO_ORDERS.value].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.promocodeFilter = undefined;
                updatedStateFields.period = undefined;
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([
                UPLOAD_TYPE.TAX_PAYMENTS_NPD.value,
                UPLOAD_TYPE.SYSTEM_TRANSACTION_TKB_EXPORT.value,
            ].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.promocodeFilter = undefined;
                updatedStateFields.period = undefined;
                updatedStateFields.fromClientId = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else if ([UPLOAD_TYPE.REFERRAL_PROMO_CODE_CLIENTS_REWARDS.value].includes(value)) {
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.promocodeFilter = undefined;
                updatedStateFields.period = undefined;
                updatedStateFields.fromClientId = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            } else {
                updatedStateFields.period = "";
                updatedStateFields.showPeriodBlock = true;
                updatedStateFields.promocodeFilter = "";
                updatedStateFields.formatType = formatFileDict.XLSX;
            }
        }

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            ...updatedStateFields,
        }),
        () => {
            if (this.isShowClientFilterByUploadType(this.state.uploadType) && isNullOrWhitespace(this.clientId)) {
                this.fetchClientListForFilter();
            }
        },
        );
    };

    searchClientName = (nameSubstringFilter) => {
        this.setState({nameSubstringFilter}, this.fetchClientListForFilter);
    };

    handleOnChangeClient = (e, {value: fromClientId}) => {
        this.setState({
            fromClientId,
        });
    };

    isShowClientFilterByUploadType(uploadType) {
        return [
            UPLOAD_TYPE.CONTRACTOR_COMMISSION_HISTORY.value,
            UPLOAD_TYPE.CLIENT_COMMISSION_HISTORY_EXPORT.value,
            UPLOAD_TYPE.ALL_PAYMENTS.value,
            UPLOAD_TYPE.PAYMENTS.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL.value,
            UPLOAD_TYPE.PAYMENTS_INDIVIDUAL.value,
            UPLOAD_TYPE.PAYMENTS_INDIVIDUAL_1C_MULTI_BANKING.value,
            UPLOAD_TYPE.PAYMENTS_1C.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C.value,
            UPLOAD_TYPE.PATENT_PAYMENTS.value,
            UPLOAD_TYPE.ORDERS_INFORMATION.value,
            UPLOAD_TYPE.CONTRACTORS_ON_ORDERS.value,
            UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value,
            UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS.value,
            UPLOAD_TYPE.CHECK_IN_OUT_EXPORT.value,
            UPLOAD_TYPE.CHECK_IN_OUT_EXPORT_CROWD_TASK.value,
            UPLOAD_TYPE.CHECK_IN_OUT_LIST_EXPORT.value,
            UPLOAD_TYPE.CROWD_CHECK_IN_OUT_LIST_EXPORT.value,
            UPLOAD_TYPE.PROJECTS_AND_OBJECTS.value,
            UPLOAD_TYPE.PAID_APIS.value,
            UPLOAD_TYPE.RESPONSES_TO_ORDERS.value,
            UPLOAD_TYPE.PAYMENTS_1C_MULTI_BANKING.value,
            UPLOAD_TYPE.PAYMENTS_CIVIL_1C_MULTI_BANKING.value,
            UPLOAD_TYPE.CROWD_TASK_EXPORT.value,
            UPLOAD_TYPE.ORDER_TEMPLATES_EXPORT.value,
        ].includes(uploadType);
    }

    get isUploadTypePromocode() {
        const {uploadType} = this.state;

        return [UPLOAD_TYPE.PROMOCODES_REFERRAL_CLIENTS.value, UPLOAD_TYPE.PROMOCODES_REFERRAL_CONTRACTORS.value].includes(uploadType);
    }

    renderName({fromClientName, fromClientId, brand}) {
        const link = LINK_CLIENT_INFO.replace(":clientId", fromClientId);
        const nameWithBrand = brand ? `${fromClientName} (${brand})` : fromClientName;

        if (NM_PARTNER === this.role) {
            return nameWithBrand;
        }

        return (
            <ExtLink
                to={link}
                historyEnabled
                noWrap
            >
                {nameWithBrand}
            </ExtLink>
        );
    }

    getClientOptions() {
        const {
            clientListForFilter,
            clientListPromocodeForFilter,
            clientListByPartnerOptions,
        } = this.props;

        if (NM_PARTNER === this.role) {
            return clientListByPartnerOptions;
        }

        return !this.isUploadTypePromocode ? clientListForFilter : clientListPromocodeForFilter;
    }

    get formatTypeOptions() {
        const {
            uploadType,
        } = this.state;

        if ([UPLOAD_TYPE.ATOL_RECEIPTS_EXPORT.value].includes(uploadType)) {
            return [FINANCE_EXPORT_OPTION_XML];
        }

        const options = !this.isUploadTypePromocode ? formatFileOptions : formatFileOptions.slice(0, -1);

        if (
            [
                UPLOAD_TYPE.CLIENT_COMMISSION_HISTORY_EXPORT.value,
                UPLOAD_TYPE.CONTRACTOR_COMMISSION_HISTORY.value,
                UPLOAD_TYPE.CLIENT_DEPOSIT_CIVIL.value,
                UPLOAD_TYPE.CLIENT_DEPOSIT.value,
                UPLOAD_TYPE.PUBLIC_CLIENT_APPLICATION_EXPORT.value,
                UPLOAD_TYPE.OBJECTS_DEPOSIT.value,
                UPLOAD_TYPE.PAID_APIS.value,
                UPLOAD_TYPE.NPD_PAYMENTS_EXPORT.value,
                UPLOAD_TYPE.LEADS_FROM_CLIENTS.value,
                UPLOAD_TYPE.TICKET_EXPORT.value,
                UPLOAD_TYPE.RESPONSES_TO_ORDERS.value,
                UPLOAD_TYPE.MY_CONTRACTORS.value,
            ].includes(uploadType)
        ) {
            return options.filter(({key}) => key !== formatFileDict.CSV);
        }

        return options;
    }

    renderModalWindow() {
        const {
            openConfirmWindow,
            confirmData: {
                content,
                onConfirm,
                confirmButton,
                cancelButton,
            },
        } = this.state;

        return openConfirmWindow &&
            <NmConfirmV2
                content={content}
                onCancel={() => {
                    this.setState({
                        openConfirmWindow: false,
                        confirmData: {},
                    });
                }}
                onConfirm={onConfirm}
                confirmButton={confirmButton}
                cancelButton={cancelButton}
            />;
    }

    renderFilter() {
        const {
            uploadType,
            exportEventType,
            fromDateFilter,
            toDateFilter,
            formatType,
            orderContractPaymentType,
            showPeriodBlock,
            nameSubstringFilter,
            fromClientId,
            period,
            promocodeFilter,
        } = this.state;

        const {
            t,
        } = this.props;

        return (
            <NmForm
                horizontal
                className="finance-export__filter"
            >
                <NmDropdownV2
                    size="lg"
                    search
                    compact
                    label={t("finance-export.type-unloading")}
                    onChange={this.handleOnChange}
                    name="uploadType"
                    placeholder={t("finance-export.type-unloading")}
                    selection
                    className={this.element("select", {uploadType: true})}
                    value={uploadType}
                    options={this.uploadTypeDict}
                />
                {
                    [UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value].includes(uploadType) &&
                    <NmDropdownV2
                        size="lg"
                        compact
                        label="Тип события"
                        onChange={this.handleOnChange}
                        name="exportEventType"
                        placeholder="Выберите тип события"
                        selection
                        className="finance-export__select"
                        value={exportEventType}
                        options={FINANCE_EXPORT_OVM_NOTIFICATION_TYPE_OPTIONS}
                    />
                }
                {this.isShowClientFilterByUploadType(uploadType) && isNullOrWhitespace(this.clientId) &&
                    <NmDropdownV2
                        size="lg"
                        label={t("client-filter.client-name")}
                        autoComplete="off"
                        isClearSearchOnBlur={false}
                        isClearable
                        className="finance-export__select"
                        searchQuery={nameSubstringFilter}
                        noResultsMessage="Нет результата поиска"
                        onSearchChange={this.searchClientName}
                        onChange={this.handleOnChangeClient}
                        search
                        value={fromClientId}
                        options={this.getClientOptions()}
                    />
                }
                {[UPLOAD_TYPE.MY_CONTRACTORS.value, UPLOAD_TYPE.NPD_PAYMENTS_EXPORT.value].includes(uploadType) &&
                    <NmDropdownV2
                        size="lg"
                        label="Период"
                        autoComplete="off"
                        name="period"
                        selectOnBlur={false}
                        className="finance-export__input"
                        onChange={this.handleOnChange}
                        selection
                        value={period}
                        options={PERIOD_OPTIONS_OPTIONS}
                    />}
                {
                    showPeriodBlock &&
                    <NmDateRangePickerV2
                        size="lg"
                        label="Период"
                        value={{fromDateFilter, toDateFilter}}
                        startFieldName="fromDateFilter"
                        endFieldName="toDateFilter"
                        className="finance-export__input"
                        isClearable={true}
                        handleOnChangeRangeDatepicker={this.handleOnChangeDatepicker}
                    />
                }
                {
                    this.isUploadTypePromocode &&
                    <NmInputV2
                        size="lg"
                        label="Промо-код"
                        value={promocodeFilter}
                        className="finance-export__input"
                        name="promocodeFilter"
                        onChange={this.handleOnChange}
                    />
                }
                {
                    ![
                        UPLOAD_TYPE.CHECK_IN_OUT_EXPORT.value,
                        UPLOAD_TYPE.CHECK_IN_OUT_EXPORT_CROWD_TASK.value,
                        UPLOAD_TYPE.CHECK_IN_OUT_LIST_EXPORT.value,
                        UPLOAD_TYPE.CROWD_CHECK_IN_OUT_LIST_EXPORT.value,
                        UPLOAD_TYPE.OVM_NOTIFICATIONS_EXPORT.value,
                    ].includes(uploadType) &&
                    <NmDropdownV2
                        size="lg"
                        compact
                        label={t("finance-export.format-file")}
                        onChange={this.handleOnChange}
                        name="formatType"
                        className="finance-export__select"
                        placeholder={t("finance-export.format-file")}
                        selection
                        value={formatType}
                        options={this.formatTypeOptions}
                    />}
                {
                    [UPLOAD_TYPE.OBJECTS_DEPOSIT.value].includes(uploadType) &&
                    <NmDropdownV2
                        size="lg"
                        compact
                        label="Тип депозита"
                        onChange={this.handleOnChange}
                        name="orderContractPaymentType"
                        className="finance-export__select"
                        placeholder={t("finance-export.format-file")}
                        selection
                        value={orderContractPaymentType}
                        options={FINANCE_EXPORT_DEPOSIT_TYPE_FILTER_OPTIONS}
                    />
                }
                <div className="finance-export__unload">
                    <NmButton
                        size="lg"
                        onClick={this.startFinanceUploadTask}
                        className="finance-export__unload"
                    >
                        {t("finance-export.start-button")}
                    </NmButton>
                </div>
            </NmForm>
        );
    };

    render() {
        const {
            loading,
            totalPages,
            totalCount,
            t,
            exportList,
        } = this.props;
        const {pageNum, pageSize} = this.state;

        return (
            <NmPage
                className="finance-export"
                header={
                    <NmTitle
                        size="xl"
                    >
                        {t("finance-export.finance-export-title")}
                    </NmTitle>
                }
                onPaginationChange={this.handlePaginationChange}
                onChangePageSize={this.handleChangePageSize}
                currentPageNum={pageNum}
                totalPages={totalPages}
                totalCount={totalCount}
                currentPageSize={pageSize}
                typeFilter="horizontal"
                filtersBase={
                    this.renderFilter()
                }
                isLoaded={loading}
            >
                {this.renderModalWindow()}
                <Task />
                {
                    exportList?.length ?
                        <CheckboxList
                            withCheckbox={false}
                            rows={this.getRows()}
                        />
                        : (
                            <NmEmptyPageV2
                                fetchProgress={loading}
                            />
                        )
                }
            </NmPage>
        );
    }
    ;
}

export default connect(
    state => ({
        pathname: state.router.location.pathname,
        totalPages: financeExportTotalPagesSelector(state),
        totalCount: financeExportTotalCountSelector(state),
        exportList: financeExportDataSelector(state),
        isAddedTaskSuccess: addFinanceExportSuccessTaskSelector(state),
        loading: financeExportLoadingSelector(state),
        clientListForFilter: clientListOptimizedOptionsSelector(state),
        clientListPromocodeForFilter: getClientListByPromocodeOptionSelector(state),
        progressExportData: progressExportDataSelector(state),
        taskInProgressIds: financeExportDataTaskIdsSelector(state),
        clientListByPartnerOptions: clientListByPartnerOptionsSelector(state),
        currentMember: clientCurrentMemberSelector(state),
        clientProperties: getClientPropertiesCardSelector(state),
        edoAccessList: edoAccessListSelector(state),
        referralPromoCodeClientsRewardsExportAvailable: referralPromoCodeClientsRewardsExportAvailableSelector(state),
    }),
    {
        getFinanceExportPage,
        addFinanceExportTask,
        updateFinanceStore,
        getOptimizedClientList,
        updateFieldClientStore,
        getClientsByPromocode,
        getProgressExport,
        getClientsByPartner,
        getEdoAccessList,
        getFileLink,
        cancelExportById,
        getReferralPromoCodeClientsRewardsExportAvailable,
    },
)(withTranslation()(FinanceExport));